:root {
	/* Color styles FIGMA */
	--white: #ffffff;
	--black: #000000;
	--gray-10: #f2f4f7;
	--gray-25: #fcfcfc;
	--gray-50: #f6f6f6;
	--gray-100: #f0f0f0;
	--gray-200: #e5e5e5;
	--gray-300: #d6d6d6;
	--gray-400: #a3a3a3;
	--gray-500: #737373;
	--gray-600: #525252;
	--gray-700: #424242;
	--gray-800: #292929;
	--gray-900: #141414;

	/*! NEW */
	--brand-25: #f0fcff;
	--brand-50: #e6fafe;
	--brand-100: #ccf7ff;
	--brand-700: #087f91;

	--focus-ring-primary-gap: 0px 0px 0px 2px #ebf4f9, 0px 0px 0px 4px #005c62;
	--focus-ring-sell-gap: 0px 0px 0px 2px #ebf4f9, 0px 0px 0px 4px #3542cd;

	--sentiment-warning: #fa0;
	--sentiment-warning-orange: #ef6820;
	--sentiment-negative: #d4372b;
	--sentiment-negative-hover: #a92c23;
	--background-gray-subtle-50: #f2f7f7;
	--background-light: #f3f7f7;
	--background-solid: #ebeeef;
	--background-elevated: #fff;
	--background-transparent: #52747a1f;

	--content-primary: #151c1e;
	--content-secondary: #485051;
	--content-tertiary: #949c9e;

	--interactive-primary: #005262;
	--interactive-primary-hover: #003c47;
	--interactive-primary-750: #016675;
	--interactive-secondary: #6e9299;
	--interactive-accent: #9eeefa;
	--interactive-accent-hover: #89e5f5;
	--interactive-accent-active: #75ddf0;
	--interactive-control: #003c47;

	--border-neutral: #1e393d1f;
	--border-overlay: #1e393d1f;
	/*! NEW */

	--smoke-gray-50: #f6f6f9;
	--smoke-gray-300: #c8ceda;
	--smoke-gray-400: #98a2b3;
	--smoke-gray-600: #475467;
	--smoke-gray-700: #344054;

	--primary-25: #f4feff;
	--primary-50: #e4f7f9;
	--primary-100: #c2ecf0;
	--primary-200: #95dce3;
	--primary-300: #65ccd6;
	--primary-400: #34c0cd;
	--primary-500: #00abbb;
	--primary-600: #008c99;
	--primary-700: #006c75;
	--primary-800: #005a62;
	--primary-900: #00383d;
	--team-sect-bg: #dbe1da;
	--team-100: #edf4eb;
	--team-200: #deeadc;
	--team-300: #c8d6c1;
	--team-400: #b9c8b1;
	--team-500: #9fb98e;
	--team-600: #82a16d;
	--team-700-main: #45801e;
	--team-800: #326212;
	--team-900: #244a0d;
	--sell-sect-bg: #e7e1db;
	--sell-50: #faf6f3;
	--sell-100: #faf3ef;
	--sell-200: #f4ede8;
	--sell-300: #ffe6d8;
	--sell-400: #f6d2bb;
	--sell-500: #e4b89f;
	--sell-600: #dc8f71;
	--sell-700-main: #b94d30;
	--sell-800: #932f19;
	--sell-900: #772917;
	--buy-50: #f7fcfd;
	--buy-100: #eef7f9;
	--buy-200: #dfeaec;
	--buy-300: #cfdde2;
	--buy-400: #a4c8d0;
	--buy-500: #75b1be;
	--buy-600: #4395ae;
	--buy-700-main: #0e7090;
	--buy-800: #155b75;
	--buy-900: #164c63;
	--notifications-25: #fcfcfd;
	--notifications-50: #f8f9fc;
	--notifications-100: #eaecf5;
	--notifications-200: #d5d9eb;
	--notifications-300: #c0c5e1;
	--notifications-400: #a2a9d7;
	--notifications-500: #828cc7;
	--notifications-600: #5f69a5;
	--notifications-700-main: #4e5690;
	--notifications-800: #363f72;
	--notifications-900: #293056;
	--analytics-25: #fefaff;
	--analytics-50: #fdf4ff;
	--analytics-100: #fbeefe;
	--analytics-200: #f5e4f9;
	--analytics-300: #ecd9f0;
	--analytics-400: #e2cdea;
	--analytics-500: #cfabdb;
	--analytics-600: #b17fc2;
	--analytics-700-main: #8f52a4;
	--analytics-800: #7e218d;
	--analytics-900: #641877;
	--apps-sect-bg: #d0cec7;
	--apps-25: #fdf9f1;
	--apps-50: #fbf4e6;
	--apps-100: #f9eed9;
	--apps-200: #f5e1b9;
	--apps-300: #f2cf8a;
	--apps-400: #e9bb62;
	--apps-500: #e4a426;
	--apps-600-main: #ca851e;
	--apps-700: #a15c07;
	--apps-800: #854a0e;
	--apps-900: #713b12;

	--sell-new-25: #f5f8ff;
	--sell-new-25: #ebf2ff;
	--sell-new-600: #475ae4;
	--sell-new-700: #3542cd;
	--sell-new-800: #2d31a6;

	--orders-100: #e8e9ff;
	--orders-700: #5a61c4;
	--orders-800: #4a3892;

	--purple-600: #815ee1;
	--pink-500: #ee46bc;
	--moss-500: #669f2a;

	--legal-100: #f5f2e1;
	--legal-200: #eee9d0;
	--legal-300: #ebe1ab;
	--legal-400: #dcc987;
	--legal-500: #bca25e;
	--legal-600: #99885b;
	--legal-700-main: #816c45;
	--legal-800: #634f15;
	--legal-900: #493a05;
	--error-25: #fffbfa;
	--error-50: #fef3f2;
	--error-100: #fee4e2;
	--error-200: #fecdca;
	--error-300: #fda29b;
	--error-400: #f97066;
	--error-500: #f04438;
	--error-600: #d92d20;
	--error-700: #b42318;
	--error-800: #912018;
	--error-900: #7a271a;
	--warning-25: #fffcf5;
	--warning-50: #fffaeb;
	--warning-100: #fef0c7;
	--warning-200: #fedf89;
	--warning-300: #fec84b;
	--warning-400: #fdb022;
	--warning-500: #f79009;
	--warning-600: #dc6803;
	--warning-700: #b54708;
	--warning-800: #93370d;
	--warning-900: #7a2e0e;
	--success-25: #f6fef9;
	--success-50: #ecfdf3;
	--success-100: #d1fadf;
	--success-200: #a6f4c5;
	--success-300: #6ce9a6;
	--success-400: #32d583;
	--success-500: #12b76a;
	--success-600: #039855;
	--success-700: #027a48;
	--success-800: #05603a;
	--success-900: #054f31;
	--job-status-orange: #ff8800;
	--job-status-green: #00b800;
	--job-status-red: #de0206;

	/* Text-size styles */
	--display-3xl-regular: 72px;
	--display-3xl-medium: 72px;
	--display-3xl-semibold: 72px;
	--display-3xl-bold: 72px;
	--display-2xl-regular: 60px;
	--display-2xl-medium: 60px;
	--display-2xl-semibold: 60px;
	--display-2xl-bold: 60px;
	--display-xl-regular: 48px;
	--display-xl-medium: 48px;
	--display-xl-semibold: 48px;
	--display-xl-bold: 48px;
	--display-lg-regular: 36px;
	--display-lg-medium: 36px;
	--display-lg-semibold: 36px;
	--display-lg-bold: 36px;
	--display-md-regular: 30px;
	--display-md-medium: 30px;
	--display-md-semibold: 30px;
	--display-md-bold: 30px;
	--display-md-medium-italic: 30px;
	--display-sm-regular: 24px;
	--display-sm-medium: 24px;
	--display-sm-semibold: 24px;
	--display-sm-bold: 24px;
	--display-sm-medium-italic: 24px;
	--display-xs-regular: 20px;
	--display-xs-medium: 20px;
	--display-xs-semibold: 20px;
	--display-xs-bold: 20px;
	--display-xxs-regular: 18px;
	--display-xxs-medium: 18px;
	--display-xxs-semibold: 18px;
	--display-xxs-bold: 18px;
	--text-xl-light: 20px;
	--text-xl-regular: 20px;
	--text-xl-semibold: 20px;
	--text-xl-bold: 20px;
	--text-xl-regular-italic: 20px;
	--text-xl-medium-italic: 20px;
	--text-xl-semibold-italic: 20px;
	--text-xl-bold-italic: 20px;
	--text-xl-regular-underlined: 20px;
	--text-lg-light: 18px;
	--text-lg-regular: 18px;
	--text-lg-semibold: 18px;
	--text-lg-bold: 18px;
	--text-lg-regular-italic: 18px;
	--text-lg-medium-italic: 18px;
	--text-lg-semibold-italic: 18px;
	--text-lg-bold-italic: 18px;
	--text-lg-regular-underlined: 18px;
	--text-lg-medium-underlined: 18px;
	--text-md-light: 16px;
	--text-md-regular: 16px;
	--text-md-semibold: 16px;
	--text-md-bold: 16px;
	--text-md-regular-italic: 16px;
	--text-md-medium-italic: 16px;
	--text-md-semibold-italic: 16px;
	--text-md-bold-italic: 16px;
	--text-md-regular-underlined: 16px;
	--text-md-medium-underlined: 16px;
	--text-sm-light: 14px;
	--text-sm-regular: 14px;
	--text-sm-semibold: 14px;
	--text-sm-bold: 14px;
	--text-sm-regular-underlined: 14px;
	--text-sm-medium-underlined: 14px;
	--text-xs-light: 12px;
	--text-xs-regular: 12px;
	--text-xs-semibold: 12px;
	--text-xs-bold: 12px;
	--buttons-btn-sm-md-14px: 14px;
	--buttons-btn-lg-xl-16px: 16px;
	--buttons-btn-2xl-18px: 18px;

	/* Effect styles */
	--neomorph-100: -2px -2px 3px rgba(255, 255, 255, 1), 2px 2px 3px rgba(223, 223, 223, 1);
	--neomorph-100-hover: -2px -2px 2px rgba(255, 255, 255, 1), 2px 2px 2px rgba(205, 205, 205, 1);
	--focus-ring-shadow-sm: 0px 1px 3px 0px rgba(16, 24, 40, 0.1), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
	--focus-ring-4px-primary-100: 0px 0px 0px 4px #c2ecf0, 1px 1px 10px 0px rgba(0, 0, 0, 0.3);
	--focus-ring-4px-gray-100: 0px 0px 0px rgba(242, 244, 247, 1);
	--focus-ring-4px-error-100: 0px 0px 0px rgba(254, 228, 226, 1);
	--focus-ring-4px-gray-500: 0px 0px 0px rgba(115, 115, 115, 1);
	--focus-ring-4px-primary-500: 1px 1px 10px rgba(0, 0, 0, 0.6), 0px 0px 0px rgba(0, 171, 187, 1);
	--shadow-xs: 0px 0px 4px rgba(0, 0, 0, 0.05);
	--shadow-xs-focused-4px-primary-100: 1px 1px 10px rgba(0, 0, 0, 0.3), 0px 0px 0px rgba(194, 236, 240, 1),
		0px 1px 2px rgba(16, 24, 40, 0.05);
	--shadow-xs-focused-4px-gray-100: 0px 0px 0px rgba(242, 244, 247, 1), 0px 1px 2px rgba(16, 24, 40, 0.05);
	--shadow-xs-focused-4px-error-100: 0px 0px 0px rgba(254, 228, 226, 1), 0px 1px 2px rgba(16, 24, 40, 0.05);
	--shadow-sm: 0px 1px 4px rgba(0, 0, 0, 0.06), 0px 1px 5px rgba(0, 0, 0, 0.1);
	--shadow-sm-focused-4px-primary-100: 0px 0px 0px rgba(244, 235, 255, 1), 0px 1px 2px rgba(16, 24, 40, 0.06),
		0px 1px 3px rgba(16, 24, 40, 0.1);
	--shadow-sm-focused-4px-gray-100: 0px 0px 0px rgba(242, 244, 247, 1), 0px 1px 2px rgba(16, 24, 40, 0.06),
		0px 1px 3px rgba(16, 24, 40, 0.1);
	--shadow-md: 0px 2px 6px rgba(0, 0, 0, 0.06), 0px 2px 8px rgba(0, 0, 0, 0.1);
	--shadow-lg: 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 16px rgba(0, 0, 0, 0.1);
	--shadow-xl: 1px 6px 8px rgba(0, 0, 0, 0.04), 2px 8px 24px rgba(0, 0, 0, 0.08);
	--shadow-2xl: 0px 12px 36px rgba(0, 0, 0, 0.18);
	--shadow-3xl: 0px 16px 48px rgba(0, 0, 0, 0.1);
	--shadow-btn: 1px 2px 6px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.12);
	--shadow-btnhover: 0px 2px 10px 0px rgba(0, 0, 0, 0.14), 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
	--shadow-portfolio-mockup-main-centre-md: 0px 75px 150px rgba(52, 64, 84, 0.14);
	--shadow-portfolio-mockup-main-centre-lg: 0px 100px 200px rgba(52, 64, 84, 0.18);
	--shadow-portfolio-mockup-overlay-right-lg: -100px 100px 150px rgba(52, 64, 84, 0.12);
	--shadow-portfolio-mockup-overlay-left-lg: 100px 100px 150px rgba(52, 64, 84, 0.12);
	--shadow-portfolio-mockup-grid-shadow-md: 32px 32px 64px rgba(52, 64, 84, 0.08);

	--lightBlue: #f9fdff;

	--gray: #808080;
	--veryLightGray: #f8f8f8;
	--lightGray: #f0f0f0;
	--mediumGray: #d4d4d4;
	--darkGray: #333;
	--dune: #372d2d;
	--gray-docket: #4f4f4f;
	--gray-25: #fcfcfc;
	--gray-30: #a1a1a1;
	--gray-50: #fafafa;
	--gray-65: #595959;
	--gray-300: #d6d6d6;
	--gray-500: #737373;
	--gray-700: #424242;

	--warningRed: #d30000;
	--successGreen: #00b800;
	--signalGreen: #02de02;
	--signalOrange: #ff8800;
	--signalYellow: #feb304;
	--signalRed: #c02f01;
	--beige: #eae2cf;
	--trustedBg: #dfded5;

	--success-500: #12b76a;

	--primary-600: #008c99;

	--apps-800: #854a0e;
	--apps: #ca851e;

	--turqMain: #00abbb;
	--turqDark: #008f9c;
	--turqDarker: #006a75;
	--turqLight: #e7fafb;
	--turqLighter: #f3ffff;
	--darkBlue: #064368;
	--darkerBlue: #09142c;
	--boxShadowDarkBackgroundHover: rgba(0, 0, 0, 0.6);
	--boxShadowDarkest: rgba(0, 0, 0, 0.4);
	--boxShadowDark: rgba(0, 0, 0, 0.25);
	--boxShadowOrder: 0px 0px 4px rgba(0, 0, 0, 0.15);
	--boxShadowShop: 2px 2px 10px 2px rgba(0, 0, 0, 0.3);
	--boxShadowWidget: 0px 8px 24px rgba(0, 0, 0, 0.1);
	--boxShadowCrslCard: 0px 8px 20px rgba(0, 0, 0, 0.1);
	--boxShadow: rgba(0, 0, 0, 0.1);
	--shadow-xl: 2px 8px 24px rgba(0, 0, 0, 0.08), 1px 6px 8px rgba(0, 0, 0, 0.04);

	--purchase: #0e7090;
	--purchase-300: #cfdde2;
	--purchaseBgListing: #81a3ba;
	--purchaseBg: #d0dade;
	--purchaseBgLight: #dce3e6;
	--purchaseBgVeryLight: #f0f3f5;
	--sale: #b94d30;
	--saleBg: #ba837f;
	--saleBgListing: #b8908d;
	--saleBgLight: #ece8e7;
	--saleBgBright: #e2dfde;
	--saleBgVeryLight: #fdf6f4;
	--analytics: #8f52a4;
	--newPurp: #493aa3;
	--notification: #4e5690;
	--notificationLight: #f5f1f8;
	--team: #45801e;
	--team-400: #b9c8b1;
	--docketTempBg: #dbe1da;
	--teamBg: #dbe1da;
	--teamBgVeryBright: #f9fbf8;
	--teamBgDark: #cad2c6;
	--teamBtnBg: #b0bbab;
	--legal: #927b56;

	--teamManageBg: #4e736624;
	--orderDetailsBg: #f9f9f9;
	--supplierBg: rgba(161, 154, 136, 0.26);
	--overviewBg: #e5e5e5;
	--learningZoneBack: #a3a3a3;
	--fadedGray: #e8e8e5;
	--filterBg: #a6a6a6;

	--bgMarked: #ebf8f7;

	--borderRadiusSharp: 6px;
	--borderRadiusReg: 12px;
	--borderRadiusDash: 16px;
	--borderRadiusCard: 20px;
	--borderRadiusBtn: 25px;

	--disabled: #ccc;

	--regular: 'wght' 400;
	--semiMedium: 'wght' 450;
	--medium: 'wght' 500;
	--semiBold: 'wght' 550;
	--bold: 'wght' 650;

	--docketYellow: #dfcc8a;
	--docketGreen: #8bc082;
	--docketPurple: #c6b1d7;
	--docketBlue: #89aadc;
	--docketRed: #d59ea1;

	/* NEW MAIN COLORS */
	--interactive-primary: #005262;
}

@-webkit-keyframes sidebarSlideInLeft {
	0% {
		left: -420px;
	}
	100% {
		left: 0px;
	}
}

@keyframes sidebarSlideInLeft {
	0% {
		left: -420px;
	}
	100% {
		left: 0px;
	}
}

@-webkit-keyframes sidebarSlideOutLeft {
	0% {
		left: 0px;
	}
	100% {
		left: -420px;
	}
}

@keyframes sidebarSlideOutLeft {
	0% {
		left: 0px;
	}
	100% {
		left: -420px;
	}
}

@-webkit-keyframes sidebarSlideInRight {
	0% {
		right: -420px;
	}
	100% {
		right: 0px;
	}
}

@keyframes sidebarSlideInRight {
	0% {
		right: -420px;
	}
	100% {
		right: 0px;
	}
}

@-webkit-keyframes sidebarSlideOutRight {
	0% {
		right: 0px;
	}
	100% {
		right: -420px;
	}
}

@keyframes sidebarSlideOutRight {
	0% {
		right: 0px;
	}
	100% {
		right: -420px;
	}
}

/* For mobile */
@-webkit-keyframes sidebarSlideInLeftMobile {
	0% {
		left: -100%;
	}
	100% {
		left: 0px;
	}
}
@keyframes sidebarSlideInLeftMobile {
	0% {
		left: -100%;
	}
	100% {
		left: 0px;
	}
}

@-webkit-keyframes sidebarSlideOutLeftMobile {
	0% {
		left: 0px;
	}
	100% {
		left: -100%;
	}
}

@keyframes sidebarSlideOutLeftMobile {
	0% {
		left: 0px;
	}
	100% {
		left: -100%;
	}
}

@-webkit-keyframes sidebarSlideInRightMobile {
	0% {
		right: -100%;
	}
	100% {
		right: 0px;
	}
}

@keyframes sidebarSlideInRightMobile {
	0% {
		right: -100%;
	}
	100% {
		right: 0px;
	}
}

@-webkit-keyframes sidebarSlideOutRightMobile {
	0% {
		right: 0px;
	}
	100% {
		right: -100%;
	}
}

@keyframes sidebarSlideOutRightMobile {
	0% {
		right: 0px;
	}
	100% {
		right: -100%;
	}
}

@-webkit-keyframes fadeInBanner {
	0% {
		opacity: 0;
		top: -15px;
	}
	50% {
		opacity: 0.5;
		top: 8px;
	}
	100% {
		opacity: 1;
		top: 0px;
	}
}

@keyframes fadeInBanner {
	0% {
		opacity: 0;
		top: -15px;
	}
	50% {
		opacity: 0.5;
		top: 8px;
	}
	100% {
		opacity: 1;
		top: 0px;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@-webkit-keyframes fadeInModal {
	0% {
		opacity: 0;
		bottom: 10vh;
	}
	100% {
		opacity: 1;
		bottom: var(--left);
	}
}

@keyframes fadeInModal {
	0% {
		opacity: 0;
		bottom: 10vh;
	}
	100% {
		opacity: 1;
		bottom: var(--left);
	}
}

@-webkit-keyframes fadeOutModal {
	0% {
		opacity: 1;
		left: 40px;
	}
	100% {
		opacity: 0;
		left: var(--left);
	}
}

@keyframes fadeOutModal {
	0% {
		opacity: 1;
		left: 40px;
	}
	100% {
		opacity: 0;
		left: var(--left);
	}
}

@-webkit-keyframes fadeInOverlay {
	0% {
		background-color: rgba(0, 0, 0, 0);
	}
	100% {
		background-color: rgba(0, 0, 0, 0.6);
	}
}

@keyframes fadeInOverlay {
	0% {
		background-color: rgba(0, 0, 0, 0);
	}
	100% {
		background-color: rgba(0, 0, 0, 0.6);
	}
}

@-webkit-keyframes fadeOutOverlay {
	0% {
		background-color: rgba(0, 0, 0, 0.6);
	}
	100% {
		background-color: rgba(0, 0, 0, 0);
	}
}

@keyframes fadeOutOverlay {
	0% {
		background-color: rgba(0, 0, 0, 0.6);
	}
	100% {
		background-color: rgba(0, 0, 0, 0);
	}
}

@-webkit-keyframes fadeOutSquish {
	0% {
		margin: var(--margin);
		height: var(--height);
		opacity: 1;
	}
	100% {
		margin: 0px;
		height: 0px;
		opacity: 0;
	}
}

@keyframes fadeOutSquish {
	0% {
		margin: var(--margin);
		height: var(--height);
		opacity: 1;
	}
	100% {
		margin: 0px;
		height: 0px;
		opacity: 0;
	}
}
@-webkit-keyframes flash {
	0% {
		-webkit-box-shadow: var(--white);
		box-shadow: var(--white);
		border-radius: 6px;
	}
	20% {
		-webkit-box-shadow: var(--focus-ring-4px-primary-100);
		box-shadow: var(--focus-ring-4px-primary-100);
		border-radius: 6px;
	}
	40% {
		-webkit-box-shadow: var(--white);
		box-shadow: var(--white);
		border-radius: 6px;
	}
	50% {
		-webkit-box-shadow: var(--focus-ring-4px-primary-100);
		box-shadow: var(--focus-ring-4px-primary-100);
		border-radius: 6px;
	}
	100% {
		-webkit-box-shadow: var(--white);
		box-shadow: var(--white);
		border-radius: 6px;
	}
}
@keyframes flash {
	0% {
		-webkit-box-shadow: var(--white);
		box-shadow: var(--white);
		border-radius: 6px;
	}
	20% {
		-webkit-box-shadow: var(--focus-ring-4px-primary-100);
		box-shadow: var(--focus-ring-4px-primary-100);
		border-radius: 6px;
	}
	40% {
		-webkit-box-shadow: var(--white);
		box-shadow: var(--white);
		border-radius: 6px;
	}
	50% {
		-webkit-box-shadow: var(--focus-ring-4px-primary-100);
		box-shadow: var(--focus-ring-4px-primary-100);
		border-radius: 6px;
	}
	100% {
		-webkit-box-shadow: var(--white);
		box-shadow: var(--white);
		border-radius: 6px;
	}
}

body {
	margin: 0;
	overflow-x: hidden;
	background-color: var(--gray-100);
}

/* Font Settings */
@font-face {
	font-family: 'Nunito';
	src: url('../assets/fonts/Nunito-VariableFont_wght.ttf') format('truetype');
	font-weight: 100 900;
	font-style: oblique 0deg 20deg;
	font-stretch: 75% 125%;
}
* {
	font-family: 'Nunito';
}
div {
	position: relative;
}
p,
span {
	font-family: 'Nunito Sans', sans-serif;
	margin: 5px 0px;
}
h1,
h2,
h3,
h4,
h5,
button {
	font-variation-settings: var(--bold);
	margin: 0;
}
h1 {
	font-size: 2.5em;
}
h2 {
	font-size: 1.5em;
}
h3 {
	font-size: 1.25em;
}
h4 {
	font-size: 1.125em;
}
h5 {
	font-size: 0.8em;
}

a {
	color: var(--black);
}

input {
	line-height: 35px;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent;
	border: none;
	width: 100%;
	margin-right: 30px;
	max-width: 200px;
	height: 40px;
	font-family: inherit;
	font-size: inherit;
	cursor: inherit;
	line-height: inherit;
	outline: none;
	padding: 10px 14px;
}

.select {
	position: relative;
	display: -ms-grid;
	display: grid;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	grid-template-areas: 'select';
	width: 230px;
	height: 40px;
	border: 1px solid var(--gray);

	border-radius: var(--borderRadiusBtn);
	cursor: pointer;
	line-height: 1.1;
	background-color: var(--white);
}

select::-ms-expand {
	display: none;
}

select:focus + .focus {
	position: absolute;
	height: 100%;
	top: -7px;
	left: -1px;
	right: -1px;
	bottom: -1px;
	border: 2px solid var(--turqMain);
	border-radius: inherit;
}

*:focus {
	outline: none !important;
}

svg,
button,
.selectCustom {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	-moz-user-select: none;
	user-select: none; /* Standard syntax */
}

button {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-decoration: none;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
}

button,
input {
	background-color: var(--white);
	border: none;
	outline: none;
	height: 38px;
	font-size: 0.94em;
}

input[type='button'],
input[type='submit'] {
	font-variation-settings: var(--bold);
}

a {
	text-decoration: none;
}

svg {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
}

body.modal-open {
	overflow: hidden;
	padding-right: 15px;
}

.avatarImgCont {
	position: relative;
	border-radius: 50%;
	height: 48px;
	width: 48px;
}
.avatarImg {
	display: block;
	border-radius: 50%;
	height: 48px;
	width: 48px;
}
.avatarImgCont.editable .imgOverlay {
	position: absolute;
	cursor: pointer;
	border-radius: 50%;
	top: 0;
	left: 0;
	height: 48px;
	width: 48px;
	background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
	opacity: 0; /* Hidden by default */
	transition: opacity 0.3s ease; /* Smooth transition */
	display: flex;
	align-items: center;
	justify-content: center;
}
.avatarImgCont.editable .imgOverlayText {
	color: var(--white);
	font-size: 20px;
	text-align: center;
}
.avatarImgCont.editable .imgOverlay:hover {
	opacity: 1;
}

.flashShadow {
	-webkit-animation: flash 1.25s ease-in-out;
	animation: flash 1.25s ease-in-out;
}

.overflowYHide {
	overflow-y: hidden;
	max-height: 0px;
}

.wrap {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.react-datepicker {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
}
.react-datepicker-popper {
	z-index: 4 !important;
}

.underline {
	text-decoration: underline;
	cursor: pointer;
}

.spanLinkText {
	display: inline-block;
	text-decoration: underline;
	cursor: pointer;
	margin: 0px;
}
.spanLinkText:hover {
	color: var(--primary-600);
}

.noBorder {
	border: none;
}

.italic {
	font-style: italic;
}

.cursor-default {
	cursor: default !important;
}
.cursor-pointer {
	cursor: pointer !important;
}
.cursor-grab {
	cursor: -webkit-grab !important;
	cursor: grab !important;
}
.cursor-text {
	cursor: text !important;
}

.pointer {
	cursor: pointer;
}

/* Team icon css */
.defaultTeamImg {
	display: flex;
	border: 1px solid var(--Border-Overlay, rgba(47, 70, 75, 0.15));
	flex-direction: column;
	justify-content: center;
	border-radius: 200px;
	align-items: center;
	max-height: 39px;
	max-width: 39px;
	flex-shrink: 0;
	height: 39px;
}

/* Error Page */
.errorPageCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	padding-top: 40px;
	width: 100%;
	gap: 20px;
}

/* Flex specific css */
.flexCenter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.flexCenterCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.flexRowStart {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}
.flexRow {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	gap: 20px;
}
.flexCol {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.disabledSVG {
	cursor: default !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	pointer-events: none !important;
	fill: var(--gray-300) !important;
	border: 1px solid var(--gray-300) !important;
}
.disabledSVG:hover {
	cursor: default !important;
	pointer-events: none !important;
	fill: var(--gray-300) !important;
	border: 1px solid var(--gray-300) !important;
}

.disabledDivWithShadow {
	opacity: 30% !important;
	cursor: default !important;
	pointer-events: none !important;
	fill: var(--boxShadow) !important;
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDark) !important;
	box-shadow: 1px 1px 6px var(--boxShadowDark) !important;
	border: none;
}
.disabledDivWithShadow:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDark) !important;
	box-shadow: 1px 1px 6px var(--boxShadowDark) !important;
}

.disabledDiv {
	opacity: 30% !important;
	cursor: default !important;
	pointer-events: none !important;
	fill: var(--boxShadow) !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border: none;
}

.disabledDiv:hover h1,
.disabledDiv:hover h2,
.disabledDiv:hover h3,
.disabledDiv:hover h4,
.disabledDiv:hover h5,
.disabledDiv:hover,
.disabledDiv:hover p,
.disabledDiv:hover a {
	color: var(--black) !important;
	cursor: default !important;
}
form {
	max-width: 100vw;
}
button:disabled,
button[disabled] {
	cursor: default !important;
	background-color: var(--white) !important;
	background-image: none !important;
	color: var(--disabled) !important;
	fill: var(--disabled) !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border: none;
}

button:disabled svg {
	fill: var(--disabled) !important;
}

button:disabled:hover,
button[disabled]:hover {
	background-color: var(--white);
	-webkit-box-shadow: none;
	box-shadow: none;
}

.highlight.warning {
	font-weight: 700;
	color: var(--warning-600);
}

.bold {
	font-variation-settings: var(--bold);
	color: var(--black);
}

.spanBold {
	color: var(--black);
	font-weight: 700;
}

.error {
	color: var(--warningRed) !important;
}

.waiting {
	color: var(--signalOrange) !important;
}

.success {
	color: var(--successGreen) !important;
}

.noWrap {
	white-space: nowrap;
}

.newLine {
	white-space: pre-line;
}

.opacity-50 {
	opacity: 0.5;
}

/** Headers */
.innerHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 10px 0px 0px 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 24px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	max-width: 680px;
	width: 100%;
	gap: 24px;
}

/** BUTTONS */
.btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-shadow: var(--shadow-btn);
	box-shadow: var(--shadow-btn);
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 0.875em;
	gap: 10px;
}
.btnCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 24px 0px;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	gap: 12px;
}
.btnCont.reverse {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}
.btnCont.modal {
	margin: 32px 0px 0px 0px;
	max-width: 600px;
	width: 100%;
}
.btnCont.modal button {
	width: 100%;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

/* Sizes */
.btn.icon {
	border: 1px solid var(--interactive-primary) !important;
	fill: var(--interactive-primary);
	border-radius: 50%;
	box-shadow: none;
	height: 48px;
	width: 48px;
}
.btn.sm {
	border-radius: 18px;
	padding: 8px 14px;
	min-width: 140px;
	height: 36px;
}
.btn.md {
	border-radius: 22px;
	padding: 10px 16px;
	min-width: 140px;
	height: 40px;
}
.btn.lg {
	border-radius: 22px;
	padding: 10px 20px;
	min-width: 140px;
	font-size: 1em;
	height: 44px;
	gap: 12px;
}
.btn.xl {
	border-radius: 24px;
	padding: 12px 20px;
	min-width: 140px;
	font-size: 1em;
	height: 48px;
	gap: 12px;
}
.btn.xxl {
	border-radius: 30px;
	padding: 16px 28px;
	font-size: 1.125em;
	min-width: 140px;
	height: 60px;
	gap: 12px;
}

/* Icon */
.btn svg {
	height: 20px !important;
	width: 20px !important;
}
.btn.icon svg {
	height: 24px !important;
	width: 24px !important;
}
.btn.xxl svg {
	height: 24px !important;
	width: 24px !important;
}
.btn.onlyIcon {
	border-radius: 18px;
	padding: 8px 14px;
	height: 36px;
}

/* Type Colors */
.btn.primary {
	background-color: var(--primary-600) !important;
	border: 1px solid var(--primary-600) !important;
	color: var(--white) !important;
}
.btn.primary svg {
	fill: var(--white) !important;
}
.btn.primary:hover {
	background-color: var(--primary-700) !important;
	border: 1px solid var(--primary-700) !important;
	-webkit-box-shadow: var(--shadow-btnhover) !important;
	box-shadow: var(--shadow-btnhover) !important;
}
.btn.primary:active {
	-webkit-box-shadow: var(--focus-ring-4px-primary-100) !important;
	box-shadow: var(--focus-ring-4px-primary-100) !important;
}
.btn.primary:disabled {
	background-color: var(--primary-200) !important;
	border: 1px solid var(--primary-200) !important;
	color: var(--white) !important;
	fill: var(--white) !important;
	cursor: default !important;
}
.btn.primary:disabled svg {
	fill: var(--white) !important;
}

.btn.destructive {
	background-color: var(--sentiment-negative) !important;
	border: 1px solid var(--sentiment-negative) !important;
	color: var(--white) !important;
}
.btn.destructive svg {
	fill: var(--white) !important;
}
.btn.destructive:hover {
	background-color: var(--sentiment-negative-hover) !important;
	border: 1px solid var(--sentiment-negative-hover) !important;
	-webkit-box-shadow: var(--shadow-btnhover) !important;
	box-shadow: var(--shadow-btnhover) !important;
}
.btn.destructive:active {
	-webkit-box-shadow: var(--focus-ring-4px-primary-100) !important;
	box-shadow: var(--focus-ring-4px-primary-100) !important;
}
.btn.destructive:disabled {
	background-color: var(--gray-100) !important;
	border: 1px solid var(--gray-100) !important;
	color: var(--white) !important;
	fill: var(--white) !important;
	cursor: default !important;
}
.btn.destructive:disabled svg {
	fill: var(--white) !important;
}

.btn.destructive-tertiary {
	color: var(--sentiment-negative) !important;
	background-color: var(--white);
	box-shadow: none;
}
.btn.destructive-tertiary svg {
	fill: var(--white) !important;
}
.btn.destructive-tertiary:hover {
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12), 0px 1px 4px 0px rgba(0, 0, 0, 0.24) !important;
	background: var(--sentiment-negative-light, #fee4e2) !important;
}
.btn.destructive-tertiary:active {
	box-shadow: 0px 0px 0px 2px #fff, 0px 0px 0px 4px #d92d20 !important;
}
.btn.destructive-tertiary:disabled {
	background-color: var(--gray-100) !important;
	border: 1px solid var(--gray-100) !important;
	color: var(--white) !important;
	fill: var(--white) !important;
	cursor: default !important;
}
.btn.destructive-tertiary:disabled svg {
	fill: var(--white) !important;
}

.btn.secondary {
	background-color: var(--white) !important;
	border: 1px solid var(--white) !important;
	color: var(--gray-600) !important;
	fill: var(--gray-600) !important;
}
.btn.secondary svg {
	fill: var(--gray-600) !important;
}
.btn.secondary:hover {
	background-color: var(--gray-100) !important;
	border: 1px solid var(--gray-100) !important;
	-webkit-box-shadow: var(--shadow-btnhover) !important;
	box-shadow: var(--shadow-btnhover) !important;
}
.btn.secondary:active {
	-webkit-box-shadow: var(--focus-ring-4px-primary-100) !important;
	box-shadow: var(--focus-ring-4px-primary-100) !important;
}
.btn.secondary:disabled {
	border: 1px solid var(--gray-200) !important;
	background-color: var(--white) !important;
	color: var(--gray-300) !important;
	cursor: default !important;
}
.btn.secondary:disabled svg {
	fill: var(--gray-300) !important;
}

.btn.tertiary-primary {
	background-color: transparent;
	color: var(--primary-600) !important;
	fill: var(--primary-600) !important;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.btn.tertiary-primary:hover {
	background-color: var(--primary-50) !important;
}
.btn.tertiary-primary:active {
	-webkit-box-shadow: var(--focus-ring-4px-primary-100) !important;
	box-shadow: var(--focus-ring-4px-primary-100) !important;
}
.btn.tertiary-primary:disabled {
	background-color: transparent !important;
	color: var(--gray-300) !important;
	fill: var(--gray-300) !important;
	cursor: default !important;
	border: none !important;
}
.btn.tertiary-primary:disabled svg {
	fill: var(--gray-300) !important;
}

.btn.tertiary-secondary {
	background-color: transparent;
	color: var(--gray-500) !important;
	fill: var(--gray-500) !important;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.btn.tertiary-secondary:hover {
	background-color: var(--gray-100);
}
.btn.tertiary-secondary:active {
	-webkit-box-shadow: var(--focus-ring-4px-primary-100);
	box-shadow: var(--focus-ring-4px-primary-100);
}
.btn.tertiary-secondary:disabled {
	background-color: transparent !important;
	color: var(--gray-300) !important;
	fill: var(--gray-300) !important;
	cursor: default !important;
	border: none !important;
}
.btn.tertiary-secondary:disabled svg {
	fill: var(--gray-300) !important;
}

.btn.accent {
	background: var(--interactive-accent, #9eeefa) !important;
	color: var(--interactive-control, #003c47) !important;
	fill: var(--interactive-control, #003c47) !important;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.btn.accent:hover {
	background: var(--interactive-accent-hover, #89e5f5);
}
.btn.accent:active {
	background: var(--interactive-accent-active, #75ddf0);
}
.btn.accent:disabled {
	background-color: transparent !important;
	color: var(--gray-300) !important;
	fill: var(--gray-300) !important;
	cursor: default !important;
	border: none !important;
}
.btn.accent:disabled svg {
	fill: var(--gray-300) !important;
}

/* Btn category colors */
.btn.primary.sale {
	background-color: var(--sell-new-800) !important;
}
.btn.primary.sale:hover {
	background-color: var(--sell-new-800) !important;
}
.btn.secondary.sale {
	color: var(--sell-new-600) !important;
}
.btn.secondary.sale svg {
	fill: var(--sell-new-600) !important;
}
.btn.tertiary-secondary.sale {
	color: var(--sell-new-600) !important;
	background-color: none;
}
.btn.tertiary-secondary.sale svg {
	fill: var(--sell-new-600) !important;
}
.btn.tertiary-secondary.sale.active {
	background-color: var(--gray-50) !important;
}
.btn.tertiary-secondary.sale:hover {
	background-color: var(--gray-50) !important;
}
.btn.noFill svg {
	fill: none !important;
}

.btn:disabled:hover {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

/* ! "OLD" BTN CSS */
.btn3-primary,
.btn3-secondary,
.btn3-split {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.submitBtnCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 40px 0px 100px 0px;
	gap: 12px;
}

.btn1-medium {
	min-width: 180px;
	height: 40px;
	padding: 0px 20px;
	border-radius: var(--borderRadiusBtn);
	color: var(--white);
	font-variation-settings: var(--bold);
	-webkit-box-shadow: 1px 1px 10px var(--boxShadowDarkest);
	box-shadow: 1px 1px 10px var(--boxShadowDarkest);
	background-color: var(--turqMain);
}
.btn1-medium:hover {
	-webkit-box-shadow: 1px 1px 10px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 10px var(--boxShadowDarkBackgroundHover);
}

.btn2-small {
	min-width: 160px;
	height: 32px;
	padding: 0px 20px;
	border-radius: var(--borderRadiusBtn);
	color: var(--black);
	font-variation-settings: var(--bold);
	-webkit-box-shadow: 1px 1px 10px var(--boxShadowDarkest);
	box-shadow: 1px 1px 10px var(--boxShadowDarkest);
	background-color: var(--white);
}
.btn2-small:hover {
	-webkit-box-shadow: 1px 1px 10px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 10px var(--boxShadowDarkBackgroundHover);
}

.btn2-medium {
	min-width: 180px;
	height: 40px;
	padding: 0px 20px;
	border-radius: var(--borderRadiusBtn);
	color: var(--black);
	font-variation-settings: var(--bold);
	-webkit-box-shadow: 1px 1px 10px var(--boxShadowDarkest);
	box-shadow: 1px 1px 10px var(--boxShadowDarkest);
	background-color: var(--white);
}
.btn2-medium:hover {
	-webkit-box-shadow: 1px 1px 10px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 10px var(--boxShadowDarkBackgroundHover);
}

.btn3-primary {
	min-width: 140px;
	height: 32px;
	padding: 0px 20px;
	border-radius: var(--borderRadiusBtn);
	color: var(--white);
	font-variation-settings: var(--bold);
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	background-color: var(--primary-600);
}
.btn3-primary.small {
	min-width: 80px;
	height: 50px;
}
.btn3-secondary {
	min-width: 140px;
	height: 32px;
	padding: 0px 20px;
	border-radius: var(--borderRadiusBtn);
	color: var(--gray);
	font-variation-settings: var(--bold);
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	background-color: var(--white);
}
.btn3-split {
	min-width: 140px;
	height: 32px;
	padding: 0px 0px 0px 20px;
	border-radius: var(--borderRadiusBtn);
	color: var(--gray);
	font-variation-settings: var(--bold);
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	background-color: var(--white);
}
.btn3-inverted {
	min-width: 140px;
	height: 32px;
	padding: 0px 20px;
	border-radius: var(--borderRadiusBtn);
	color: var(--gray);
	font-variation-settings: var(--bold);
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	background-color: var(--white);
}
.btn4-withIcon {
	color: var(--turqMain);
	height: 40px;
	min-width: 140px;
	padding: 0px 14px 0px 0px;
	border-radius: var(--borderRadiusBtn);
	font-variation-settings: var(--bold);
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	background-color: var(--white);
}
.btn2-primary:hover,
.btn3-primary:hover,
.btn3-split:hover,
.btn3-secondary:hover,
.btn3-inverted:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
}

.btn3-secondary .btnIconLeft {
	margin-right: 8px;
	height: 20px;
	width: 20px;
	fill: var(--gray);
}
.btn3-secondary .btnIconRight {
	margin-left: 8px;
	height: 20px;
	width: 20px;
	fill: var(--gray);
}

.btn3-primary.wide,
.btn3-secondary.wide {
	width: 200px;
}

.btn3-primary.thick,
.btn3-secondary.thick {
	height: 40px;
}

.btn3-split.noHover:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
}

.hug {
	width: 160px;
	height: 40px;
}

.btn3-split .caretCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	border-left: 1px solid var(--gray);
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 0px 25px 25px 0px;
	padding: 0px 6px;
	margin-left: 12px;
	fill: var(--gray);
	height: 100%;
}
.btn3-split .caretCont:hover {
	-webkit-filter: brightness(50%);
	filter: brightness(50%);
}
.btn3-split .caret {
	height: 24px;
	width: 24px;
}

.btnCont .splitBtnDropdown {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	background-color: var(--white);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	position: absolute;
	border-radius: 6px;
	min-width: 200px;
	padding: 12px;
	bottom: 120%;
	z-index: 10;
	right: 0px;
}
.btnCont .btnTextSpan {
	margin-left: 6px;
	font-weight: 700;
}
.btnCont .splitBtnDropdown span {
	border-radius: 6px;
	font-weight: 700;
	cursor: pointer;
	padding: 6px;
}
.btnCont .splitBtnDropdown span:hover {
	background-color: var(--lightGray);
}

.buttonDashboard2 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	width: 180px;
	height: 40px;

	border-radius: var(--borderRadiusBtn);
	padding: 0px 30px 0px 10px;
	color: var(--turqMain);
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	font-size: 1em;
}
.buttonDashboard2:hover {
	background-color: var(--veryLightGray);
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
}
.buttonDashboard2 svg,
.buttonDashboard2 path {
	height: 24px;
	width: 24px;
	fill: var(--turqMain) !important;
}

.buttonSecondary {
	cursor: pointer;
	width: 230px;
	height: 50px;
	border: 1px solid var(--turqDark);

	border-radius: var(--borderRadiusBtn);
	padding: 0px 10px;
	color: var(--turqDark);
	font-size: 1.125em;
}

.buttonSecondary:hover {
	-webkit-box-shadow: 1px 0px 6px 1px var(--black);
	box-shadow: 1px 0px 6px 1px var(--black);
	background-color: var(--veryLightGray);
}

.functionalButtonPrimary {
	cursor: pointer;
	width: 175px;
	height: 30px;
	line-height: 12.5px;

	border-radius: var(--borderRadiusBtn);
	padding: 0px 10px;
	color: var(--white);
	background-color: var(--turqMain);
	font-size: 0.88em;
}

.functionalButtonPrimary:hover {
	-webkit-box-shadow: 1px 0px 4px 1px var(--black);
	box-shadow: 1px 0px 4px 1px var(--black);
	background-color: var(--turqDark);
}

.functionalButtonSecondary {
	cursor: pointer;
	min-width: 175px;
	line-height: 12.5px;
	height: 30px;
	border: 1px solid var(--turqMain);

	border-radius: var(--borderRadiusBtn);
	padding: 0px 10px;
	color: var(--turqMain);
	font-size: 0.94em;
}

.functionalButtonSecondary:hover {
	-webkit-box-shadow: 1px 0px 4px 1px var(--black);
	box-shadow: 1px 0px 4px 1px var(--black);
	background-color: var(--veryLightGray);
}

.functionalButtonSecondaryDark {
	cursor: pointer;
	min-width: 175px;
	height: 30px;

	border-radius: var(--borderRadiusBtn);
	padding: 0px 10px;
	background-color: #eee;
	color: var(--gray);
	font-size: 0.94em;
}

.functionalButtonSecondaryLight {
	cursor: pointer;
	min-width: 160px;
	height: 30px;

	border-radius: var(--borderRadiusBtn);
	padding: 0px 10px;
	background-color: var(--white);
	color: var(--gray);
	font-size: 0.94em;
}

.functionalButtonSecondaryDark:hover,
.functionalButtonSecondaryLight:hover {
	background-color: #d9d9d9;
	-webkit-box-shadow: 1px 0px 6px 1px var(--black);
	box-shadow: 1px 0px 6px 1px var(--black);
}

/* Margin related css */
.margin-6 {
	margin: 6px;
}
.marginLeft-6 {
	margin-left: 6px;
}
.marginRight-6 {
	margin-right: 6px;
}
.marginTop-6 {
	margin-top: 6px;
}
.marginBottom-6 {
	margin-bottom: 6px;
}
.margin-10 {
	margin: 10px;
}
.margin-20 {
	margin: 20px;
}
.marginLeft-10 {
	margin-left: 10px;
}
.marginRight-10 {
	margin-right: 10px;
}
.marginTop-10 {
	margin-top: 10px;
}
.marginBottom-10 {
	margin-bottom: 10px;
}
.marginLeft-20 {
	margin-left: 20px;
}
.marginRight-20 {
	margin-right: 20px;
}
.marginTop-20 {
	margin-top: 20px;
}
.marginBottom-20 {
	margin-bottom: 20px;
}
.marginLeft-40 {
	margin-left: 40px;
}
.marginRight-40 {
	margin-right: 40px;
}
.marginTop-40 {
	margin-top: 40px;
}
.marginBottom-40 {
	margin-bottom: 40px;
}
.marginLeft-auto {
	margin-left: auto;
}
.marginRight-auto {
	margin-right: auto;
}
.marginTop-auto {
	margin-top: auto;
}
.marginBottom-auto {
	margin-bottom: auto;
}

/* Padding related CSS */
.padding-10 {
	padding: 10px;
}
.paddingLeft-10 {
	padding-left: 10px;
}
.paddingRight-10 {
	padding-right: 10px;
}
.paddingTop-10 {
	padding-top: 10px;
}
.paddingBottom-10 {
	padding-bottom: 10px;
}
.paddingLeft-20 {
	padding-left: 20px;
}
.paddingRight-20 {
	padding-right: 20px;
}
.paddingTop-20 {
	padding-top: 20px;
}
.paddingBottom-20 {
	padding-bottom: 20px;
}
.paddingLeft-40 {
	padding-left: 40px;
}
.paddingRight-40 {
	padding-right: 40px;
}
.paddingTop-40 {
	padding-top: 40px;
}
.paddingBottom-40 {
	padding-bottom: 40px;
}
.paddingLeft-auto {
	padding-left: auto;
}
.paddingRight-auto {
	padding-right: auto;
}
.paddingTop-auto {
	padding-top: auto;
}
.paddingBottom-auto {
	padding-bottom: auto;
}

/* Float CSS */
.floatRight {
	float: right;
}
.floatLeft {
	float: left;
}

/* Containers */
.pageContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-right: auto;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	margin-left: 70px;
	min-height: 87vh;
}
.pageContainer.leftExpandedMargin {
	margin-left: 272px;
}
.pageContainer.noMargin {
	margin-left: 0px;
}

.pageInnerCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 28px 60px 60px 60px;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 25px;
	max-width: 1540px;
	height: 100%;
	width: 100%;
}
.pageInnerCont.lightGray {
	background-color: var(--gray-100);
}
.pageInnerCont.gray {
	background-color: var(--gray-300);
}
.pageInnerCont.white {
	background-color: var(--white);
}
.pageInnerCont.overviewGray {
	background-color: var(--overviewBg);
}
.pageInnerCont .wrapperHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 30px 24px;
	font-size: 1.5em;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	max-width: 100%;
	width: 100%;
	gap: 20px;
}
.pageInnerCont .wrapperHeader .subHeader {
	color: var(--black);
	font-size: 1.125em;
	margin-left: 48px;
	font-weight: 400;
}
.pageInnerCont .wrapperHeader svg {
	fill: var(--black);
	height: 28px;
	width: 28px;
}
.pageInnerCont .wrapperHeader.sale {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	color: var(--sell-new-800);
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 30px 24px;
	font-size: 1em;
	width: 100%;
	gap: 20px;
}
.pageInnerCont .wrapperHeader.sale h1 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 1.5em;
	gap: 20px;
}
.pageInnerCont .wrapperHeader.sale h1 svg {
	fill: var(--sell-new-800);
	height: 28px;
	width: 28px;
}
.pageInnerCont .wrapperInner.form {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--gray-50);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 20px;
	padding: 40px 72px;
	max-width: 100%;
	height: 100%;
	width: 100%;
}

.pageInnerCont .wrapperInner {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--white);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 20px;
	max-width: 100%;
	padding: 24px;
	height: 100%;
	width: 100%;
	gap: 20px;
}
.pageInnerCont .innerCont {
	max-width: 1000px;
	width: 100%;
}
.pageInnerCont .innerCont .formTextarea {
	background-color: var(--warning-25);
	border-radius: 8px;
	margin-top: 16px;
	max-width: 100%;
	height: 130px;
	resize: none;
	width: 100%;
}
.pageInnerCont .sectionWrapper {
	position: relative;
	width: 100%;
}

.centerOnPage {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
}

.centerContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	max-width: 1680px;
	min-height: 100%;
	width: 100%;
}

.versionText {
	color: var(--gray);
	padding-top: 40px;
	margin-top: auto;
	max-width: 70%;
}

.mainInput {
	background-color: var(--lightGray);
	padding: 0px 10px;
	width: 200px;
	font-size: 1.5em;
}

.pageStart {
	position: absolute;
	visibility: hidden;
	top: 0px;
}

.pageEnd {
	position: absolute;
	visibility: hidden;
	bottom: 0px;
}

.overlay {
	position: fixed;
	z-index: 2;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.6);
	-webkit-backdrop-filter: blur(8px);
	backdrop-filter: blur(8px);
}

.fadeIn {
	-webkit-animation: fadeInOverlay 0.5s ease;
	animation: fadeInOverlay 0.5s ease;
	background-color: rgba(0, 0, 0, 0.6);
}

.fadeOut {
	-webkit-animation: fadeOutOverlay 0.5s ease;
	animation: fadeOutOverlay 0.5s ease;
	background-color: rgba(0, 0, 0, 0);
	-webkit-backdrop-filter: none;
	backdrop-filter: none;
	pointer-events: none;
}

.linkTextTurq {
	color: var(--turqDark);
	text-decoration: none;
}

.linkTextTurq:hover {
	color: var(--turqMain);
}

.linkText {
	color: black;
	text-decoration: none;
}

.linkText:hover {
	color: var(--turqMain);
}

.halfOpacity {
	opacity: 50%;
	cursor: default !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	color: var(--black) !important;
}
.halfOpacity:hover {
	cursor: default !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	color: var(--black) !important;
}

.borderBottom {
	border-bottom: 1px solid var(--gray);
}
.borderTop {
	border-top: 1px solid var(--gray);
}

.linesOnSides {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 0.75em;
	width: 100%;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.linesOnSides:before,
.linesOnSides:after {
	content: '';
	-webkit-box-shadow: 0 0.5px 0 var(--gray);
	box-shadow: 0 0.5px 0 var(--gray);
	margin: 0px 10px 1px 0px;
	-webkit-box-flex: 1;
	-ms-flex: 1 0 20px;
	flex: 1 0 20px;
	height: 0.5px;
}

.linesOnSides:after {
	margin: 0px 0px 0px 10px;
}

.relative {
	position: relative;
}

.bottomLine {
	border-bottom: 1px solid var(--black);
	padding-bottom: 10px;
}

/* Headers */
.mainHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	width: 100%;
}
.mainHeader .headerText {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 30px 24px;
	font-size: 1.5em;
	gap: 20px;
}
.mainHeader h2.headerText {
	padding: 0px;
	gap: 16px;
}
.headerIcon {
	height: 28px;
	width: 28px;
}

/* Navbar-related CSS */
.navigation {
	position: sticky;
	max-width: 100%;
	z-index: 95;
	width: 100%;
	top: 0px;
}
.navbarOuter {
	width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--lightGray);
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-transition: 0.15s ease-in-out;
	-o-transition: 0.15s ease-in-out;
	transition: 0.15s ease-in-out;
}
.navbarOuter .navbar {
	width: 95vw;
	max-width: 1540px;
	padding: 0px 26px 0px 20px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0px auto 0px auto;
	height: 80px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	z-index: 10;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-transition: 0.15s ease-in-out;
	-o-transition: 0.15s ease-in-out;
	transition: 0.15s ease-in-out;
}
.navbarOuter.scroll {
	-webkit-box-shadow: 0px 6px 6px -6px var(--boxShadowDark);
	box-shadow: 0px 6px 6px -6px var(--boxShadowDark);
}
.navbarOuter.scroll .navbar {
	margin-bottom: 0px;
	height: 70px;
}
.navbarOuter.scroll .navbar .logo {
	background-size: 200px 40px;
	height: 40px;
	width: 200px;
}

.navItems {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	gap: 30px;
}

.navItems .navItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	height: 40px;
	width: 40px;
}
.navItems .navItem:active {
	-webkit-box-shadow: var(--focus-ring-4px-primary-100);
	box-shadow: var(--focus-ring-4px-primary-100);
	border-radius: 8px;
}

.navItems .btnCont {
	margin: 0px;
}

.navItems .navItem img {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	cursor: pointer;
}

.navItems .navItem .defaultTeamIcon,
.navItems .navItem .userIcon {
	border-radius: 50%;
}

.navItems .navIcon {
	fill: var(--gray-700);
	cursor: pointer;
	box-sizing: border-box;
	padding: 8px;
	height: 40px;
	width: 40px;
}
.navItems .navItem:hover {
	background-color: var(--white);
	border-radius: 8px;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.navItem button {
	width: 100%;
	height: 30px;
	margin: 10px auto;
}

.navLink a {
	color: var(--gray);
	line-height: 35px;
	padding: 0px 2px;
	text-decoration: none;
	text-align: center;
	font-size: 0.875em;
}

.navItem button:hover,
.navLink:hover {
	background-color: var(--lightGray);
	cursor: pointer;

	border-radius: var(--borderRadiusBtn);
}

.hamburgerIcon {
	position: absolute;
	cursor: pointer;
	top: 18px;
	height: 40px;
	width: 40px;
	z-index: 11;
}
.hamburgerIcon:hover {
	background-color: var(--white);
	border-radius: 6px;
}
.hamburgerIcon .menuIcon {
	fill: var(--gray-700);
	border-radius: 6px;
	padding: 8px;
	height: 24px;
	width: 24px;
}

.mobileMenu {
	position: fixed;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--gray-100);
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-shadow: var(--shadow-2xl);
	box-shadow: var(--shadow-2xl);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 4px 16px;
	width: 100%;
	height: 48px;
	bottom: 0px;
	z-index: 95;
}
.mobileMenu .botMenuIconCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 8px;
	height: 32px;
	width: 32px;
}
.mobileMenu .botMenuIconCont:hover {
	background-color: var(--white);
	border-radius: 8px;
}
.mobileMenu .botMenuIcon {
	height: 20px;
	width: 20px;
}
.mobileMenu .botMenuIconCont.disabled {
	fill: var(--gray-300);
}

.dropdownFixed {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: fixed;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDark);
	box-shadow: 0px 0px 4px var(--boxShadowDark);
	border-radius: 16px 16px 0px 16px;
	padding: 20px;
	background: var(--white);
	bottom: 55px;
	right: 40px;
	min-width: 120px;
	z-index: 10;
}
.dropdownFixed.spaceBottom {
	bottom: 105px;
}

.dropdownFixed .supportCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.dropdownFixed .supportCol p {
	cursor: pointer;
	margin: 0px;
}
.dropdownFixed .supportCol p:hover {
	color: var(--turqMain);
}

.dropdownFixed .supportCol .section {
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid var(--black);
}

.dropdownFixed .supportCol .section:last-child {
	margin: 0;
	padding: 0;
	border-bottom: none;
}

.dropdown .profile p {
	font-size: 0.75em;
	color: var(--gray);
	padding: 0px 5px;
}

.dropdown .notificationUnread {
	text-decoration: none;
	color: var(--turqMain);
}

.dropdown .notificationUnread:hover {
	text-decoration: none;
	color: var(--turqDark);
}

.dropdown a {
	text-decoration: none;
	color: var(--black);
}

.dropdown a:hover {
	color: var(--turqMain);
}

.dropdown .dropdownItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	height: 40px;
	width: 100%;
	margin-bottom: 1px;
}

.dropdown .dropdownItem input {
	text-align: left;
	cursor: pointer;
	width: 100%;
	font-size: 1em;
	font-weight: 500;
	margin-left: 10px;
}

.dropdown .dropdownItemBorderBottom {
	-webkit-box-shadow: 0 0.5px 0 var(--gray);
	box-shadow: 0 0.5px 0 var(--gray);
	padding-bottom: 10px;
	margin-bottom: 10px;
	width: 100%;
}

.dropdown .dropdownItem:hover input,
.dropdown .dropdownItem:hover svg {
	color: var(--turqMain);
	fill: var(--turqMain);
}

.navItems .dropdown {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	overflow-y: auto;
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDark);
	box-shadow: 0px 0px 4px var(--boxShadowDark);
	border-radius: 12px 0px 12px 12px;
	padding: 10px;
	background: var(--white);
	top: 135%;
	right: 20px;
	min-width: 120px;
	max-height: 40vh;
	z-index: 1;
}

.navItems .dropdown .linkBtn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	min-width: 200px;
	margin: 20px auto;
}

.navItems .dropdown .notification {
	min-width: 300px;
}

.navItems .dropdown h4 {
	padding: 10px 5px;
}

.navSection {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	width: 50%;
	cursor: pointer;
}

.navSection:hover,
.navSection:hover a {
	color: var(--turqDark);
}

.navSection:hover svg,
.dropdown a:hover svg {
	fill: var(--turqDark);
}

.navSection svg {
	margin-right: 10px;
}

.navSection a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	margin-right: 10px;
}

.teamSwitchItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	width: 300px;
	padding: 3px 6px;
	margin: 1px 0px;
	border-radius: var(--borderRadiusSharp);
}
.teamSwitchItem.selected {
	border: 1px solid var(--primary-600);
}

.teamSwitchItem.selected .circle {
	margin-left: auto;
	margin-right: 4px;
	fill: var(--success-500);
	height: 10px;
}

.teamSwitchItem:hover {
	background-color: var(--gray-50);
}

.teamSwitchItem img {
	overflow: hidden;
	border-radius: var(--borderRadiusBtn);
	margin-right: 10px;
	-o-object-fit: cover;
	object-fit: cover;
	height: 40px;
	width: 40px;
}

.teamSwitchItem p {
	max-width: 100%;
	width: 200px;
}

.teamSwitchItem .icon {
	height: 40px;
	width: 40px;
}

.teamSwitchItem .teamChoicesIcon.purple:hover {
	background-color: var(--purple-600);
}
.teamSwitchItem .teamChoicesIcon.pink:hover {
	background-color: var(--pink-500);
}
.teamSwitchItem .teamChoicesIcon.green:hover {
	background-color: var(--moss-500);
}

/* Topbar */
.topbar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: sticky;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: var(--darkGray);
	-webkit-transition: 0.15s ease-in-out;
	-o-transition: 0.15s ease-in-out;
	transition: 0.15s ease-in-out;
	left: calc(-50vw + 50%);
	color: var(--white);
	min-height: 36px;
	width: 100vw;
	z-index: 98;
	top: 0px;
}
.topbar.scroll {
	min-height: 30px;
}

.topbar h4 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 0.88em;
	margin-left: 10px;
	font-variation-settings: var(--semiMedium);
}

.topbar .link {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	cursor: pointer;
	color: var(--white);
	margin: 0px 40px;
	min-height: 30px;
}
.topbar .link.selected {
	color: var(--turqMain);
}
.topbar .link.selected svg {
	fill: var(--turqMain);
}

.topbar svg {
	fill: var(--white);
	height: 19px;
	margin: auto;
	width: 19px;
}

/* Menu */
.profileItem {
	width: 100%;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	margin-bottom: 1px;
	-webkit-box-shadow: 0 0.5px 0 var(--gray);
	box-shadow: 0 0.5px 0 var(--gray);
}

.profileContainer,
.profileContainerChoice {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	word-break: break-all;
	padding: 10px;
}

.profileContainer .userIcon {
	fill: var(--gray-700);
	cursor: default;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	padding: 6px;
	height: 28px;
	width: 28px;
	text-align: center;
	color: var(--white);
	border-radius: 50%;
}

.profileContainer,
img {
	overflow: hidden;
	-o-object-fit: cover;
	object-fit: cover;
	cursor: default;
}

.profile .profileContainer img {
	overflow: hidden;
	-o-object-fit: cover;
	object-fit: cover;
	cursor: default;
}

.profileContainerChoice {
	padding: 10px 10px 10px 10px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin-bottom: 10px;
	border-radius: 6px;
	cursor: pointer;
	max-width: 100%;
}
.profileContainerChoice h4 {
	word-break: keep-all;
	font-size: 1em;
}

.profileContainerChoice:hover {
	background-color: var(--gray-25);
	-webkit-box-shadow: var(--shadow-sm);
	box-shadow: var(--shadow-sm);
}

.profilesSelect {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.manageButton {
	background-color: var(--lightGray);
	border: 1px solid var(--gray);
	border-radius: var(--borderRadiusReg);
	font-size: 0.75em;
	margin: 10px 0px;
	height: 20px;
	width: 100%;
}

/* Searchbox-related CSS */
.searchBarOrders {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	position: relative;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 20px 0px;
}

.searchInput {
	border: 1px solid var(--gray);
	border-radius: 8px;
	padding: 0px 20px;
	font-size: 0.875em;
	width: 250px;
}

.subText {
	color: var(--gray);
	font-size: 0.875em;
}

.searchText {
	margin: 10px;
	font-weight: 600;
	font-size: 1.12em;
}

.searchDropdownBtn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: absolute;
	margin-left: 10px;
	height: 32px;
	color: var(--gray);
	border-right: 1px solid var(--gray);
}

.searchDropdownBtn p {
	margin-right: 5px;
	font-size: 0.875em;
}

.logo {
	display: block;
	width: 200px;
	height: 40px;
	background: url('../assets/svg/procuur-logo.svg');
	background-size: 200px 40px;
	-webkit-transition: 0.15s ease-in-out;
	-o-transition: 0.15s ease-in-out;
	transition: 0.15s ease-in-out;
}

.logoSmall {
	display: block;
	width: 32px;
	height: 32px;
	background: url('../assets/svg/favicon.svg');
	background-size: 32px 32px;
	-webkit-transition: 0.15s ease-in-out;
	-o-transition: 0.15s ease-in-out;
	transition: 0.15s ease-in-out;
}

/* Supplier List CSS */
.supplierListCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	max-width: 1540px;
	height: 100%;
	border-radius: var(--borderRadiusBtn);
	background-color: var(--trustedBg);
	margin: 0px auto;
}

.supplierList {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	max-width: 1040px;
	padding: 40px 40px 0px 40px;
	height: 100%;
	width: 100%;
}

.supplierListCont .supplier {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-shadow: 1px 1px 6px 1px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px 1px var(--boxShadowDarkest);
	background-color: var(--lightGray);
	border: 3px solid var(--white);
	border-radius: var(--borderRadiusReg);
	padding: 0px 10px;
	margin: 10px 0px;
	height: 60px;
}

.supplierListCont .filterHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	padding: 0px 10px;
	color: var(--gray);
	font-size: 0.75em;
}
.supplierListCont .filterHeader p:nth-child(1) {
	min-width: 30%;
	margin: 0px 10px 0px 5px;
}
.supplierListCont .filterHeader p:nth-child(2) {
	max-width: 40%;
	width: 320px;
	margin: 0px 10px 0px 5px;
}
.supplierListCont .filterHeader p:nth-child(3) {
	margin: 0px 10px 0px 5px;
	width: 100px;
	max-width: 100px;
}
.supplierListCont .filterHeader p:nth-child(4) {
	margin: 0px 10px 0px 5px;
	max-width: 168px;
	width: 100%;
}
.supplierListCont .filterHeader p:nth-child(5),
.supplierListCont .filterHeader p:nth-child(6) {
	width: 8%;
}

.supplierListCont .supplier .bodyRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
}

.supplierListCont .supplier .bodyRow .linkText {
	font-size: 1.125em;
	min-width: 30%;
	max-width: 30%;
	font-variation-settings: var(--bold);
	margin: 0px 10px 0px 5px;
}
.supplierListCont .supplier .bodyRow p {
	font-size: 0.875em;
	max-width: 40%;
	width: 320px;
}
.supplierListCont .supplier .bodyRow .certRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-right: auto;
	margin-left: 20px;
	max-width: 100px;
	width: 100px;
}
.supplierListCont .supplier .bodyRow .certRow .listingIcon {
	fill: var(--turqMain);
	height: 28px;
	width: 28px;
}
.supplierListCont .supplier .bodyRow .iconRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	max-width: 200px;
	margin-left: auto;
	margin-right: 5px;
}
.supplierListCont .supplier .bodyRow .iconRow .iconCont {
	margin: 0px 10px;
}

/* List sorting bar */
.sortBar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.sortBar .sortType {
	padding: 5px;
	color: var(--gray);
	border-top: 1px solid var(--gray);
	border-bottom: 1px solid var(--gray);
	border-right: 1px solid var(--gray);
	cursor: pointer;
}

.sortBar .sortType:hover {
	-webkit-box-shadow: 1px 0px 6px 1px var(--black);
	box-shadow: 1px 0px 6px 1px var(--black);
}

.sortBar .sortType:first-child {
	border-left: 1px solid var(--gray);
	border-radius: 25px 0px 0px 25px;
}

.sortBar .sortType:last-child {
	border-radius: 0px 25px 25px 0px;
}

/* Breadcrumb bar */
.breadcrumbContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	min-height: 25px;
	max-width: 1540px;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	padding: 0px 25px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.breadcrumbContainer a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 600;
	font-size: 0.75em;
	text-decoration: none;
	color: var(--gray-400);
}
.breadcrumbContainer a span {
	margin: 0px;
}

.breadcrumbContainer a:hover {
	color: var(--gray-300);
}
.breadcrumbContainer a:hover .breadcrumbIcon {
	color: var(--gray-300);
	fill: var(--gray-300);
}

.hidden {
	display: none !important;
}

/* Tooltips */
.tooltipError {
	position: absolute;
	background-color: var(--white);
	border-radius: 5px;
	padding: 10px 20px;
	z-index: 2;
	max-width: 320px;
	top: 80%;
	color: var(--signalOrange);
	font-variation-settings: var(--semiBold);
	font-size: 1em;
	-webkit-box-shadow: 0px 0px 6px var(--boxShadowDark);
	box-shadow: 0px 0px 6px var(--boxShadowDark);
}
.tooltipError::before {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	bottom: 100%;
	border: 0.5em solid transparent;
	border-top: none;
	border-bottom-color: var(--white);
	-webkit-filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.2));
	filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.2));
}

.react-tooltip {
	z-index: 9;
	max-width: max(400px, 100%);
}

.tooltipExtendedText {
	background-color: var(--gray-700);
	box-sizing: border-box;
	color: var(--white);
	max-width: 360px;
	padding: 6px 50px 6px 12px;
	border-radius: 6px;
}
.tooltipExtendedText svg {
	position: absolute;
	cursor: pointer;
	height: 20px;
	fill: var(--white);
	width: 20px;
	right: 28px;
	top: 35px;
}

/* Scrollbar */
/* ::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: var(--lightGray);
}

::-webkit-scrollbar-thumb {
	background: #888;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
} */

/* Loading Circle */
.lds-dual-ring {
	position: relative;
	margin: 25% auto;
	width: 80px;
	height: 80px;
}

.lds-dual-ring:after {
	content: ' ';
	position: relative;
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid var(--mediumGray);
	border-color: var(--mediumGray) transparent var(--mediumGray) transparent;
	-webkit-animation: lds-dual-ring 1.2s linear infinite;
	animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring.small {
	height: 60px;
	width: 60px;
	margin: 0px;
}
.lds-dual-ring.small:after {
	height: 40px;
	width: 40px;
	margin: 0px;
}

.lds-dual-ring.medium {
	width: 80px;
	height: 80px;
	margin: 10% auto;
}
.lds-dual-ring.medium:after {
	width: 64px;
	height: 64px;
	margin: 0px;
}

.lds-dual-ring.btnLoad {
	height: 32px;
	width: 32px;
	margin: 0px;
}
.lds-dual-ring.btnLoad:after {
	height: 20px;
	width: 20px;
	margin: 0px;
}

/* Procuur loading icon */
.procuurLoadingIconCont {
	position: relative;
	margin: 25% auto;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	/* padding: 18px; */
	/* box-shadow: 0 0 0 rgba(0, 206, 209, 0); */
	/* border: 1px solid rgba(0, 206, 209, 0); */
	/* animation: pulse 2s infinite ease-in-out; */
}

@keyframes pulse {
	0%,
	100% {
		box-shadow: 0 0 0 rgba(0, 206, 209, 0);
	}
	50% {
		border: 1px solid rgba(0, 206, 209, 0.3);
		box-shadow: 0 0 15px 1px rgba(0, 206, 209, 0.3);
	}
}

@-webkit-keyframes lds-dual-ring {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes lds-dual-ring {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

/* Loading Facebook */
.lds-facebook {
	position: relative;
	margin: auto;
	width: 80px;
	height: 80px;
}
.lds-facebook div {
	display: inline-block;
	position: absolute;
	left: 8px;
	width: 16px;
	background: var(--mediumGray);
	-webkit-animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
	animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
	left: 8px;
	-webkit-animation-delay: -0.24s;
	animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
	left: 32px;
	-webkit-animation-delay: -0.12s;
	animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
	left: 56px;
	-webkit-animation-delay: 0;
	animation-delay: 0;
}
@-webkit-keyframes lds-facebook {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}
@keyframes lds-facebook {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}

/* Tabs */
.tabsContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	border-radius: 3px;
	padding-top: 40px;
	width: 100%;
	gap: 4px;
}

.tabsContainer .tabItem:nth-child(1).selected:before,
.tabsContainer .tabItem:nth-child(1):hover:before {
	content: '' !important;
	position: absolute !important;
	width: 0 !important;
	height: 0 !important;
	border: 0.6em solid !important;
	border-color: var(--white) !important;
	top: 100%;
	left: 0px;
}

.tabsContainer .tabItem {
	display: flex;
	color: var(--interactive-secondary, #6e9299);
	fill: var(--interactive-secondary, #6e9299);
	border-radius: 24px 24px 0px 0px;
	padding: 14px 20px 10px 20px;
	align-items: center;
	cursor: pointer;
	gap: 8px;
}
.tabsContainer .tabItem h4 {
	font-variation-settings: 'wght' 650;
	font-size: 1em;
	margin: 0px;
}
.tabsContainer .tabItem .tabIcon {
	height: 24px;
	width: 24px;
}

.tabsContainer .tabItem:hover {
	color: var(--interactive-primary-750, #016675);
	fill: var(--interactive-primary-750, #016675);
	background-color: var(--white);
}

.tabsContainer .tabItem.selected {
	color: var(--interactive-primary-750, #016675);
	fill: var(--interactive-primary-750, #016675);
	background-color: var(--white);
}

.tabsWrapper {
	display: flex;
	flex-direction: row;
	border-radius: 20px;
	background-color: var(--white);
	justify-content: center;
	box-sizing: border-box;
	height: 100%;
	width: 100%;
	gap: 48px;
}

/* User managment */
.userManageCont {
	display: flex;
	background-color: var(--background-gray-subtle-50, #f2f7f7);
	flex-direction: column;
	border-radius: 24px;
	padding: 24px;
}

.userManageCont form {
	display: flex;
	align-items: flex-start;
	max-width: 400px;
}
.userManageCont form .formRowCenter {
	align-items: flex-start;
	padding: 0px;
	margin: 0px;
}

.userManageCont .header {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.userManageCont .header h2 {
	display: flex;
	align-items: center;
	margin: 0px;
	gap: 16px;
}

/* Tutorial */
.tutorialPrompt {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: fixed;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 270px;
	bottom: 50px;
	right: 50px;
	padding: 25px;
	background-color: var(--white);
	border-radius: 16px 16px 6px 16px;
	-webkit-box-shadow: 0px 0px 6px var(--boxShadowDarkest);
	box-shadow: 0px 0px 6px var(--boxShadowDarkest);
}

.tutorialModal {
	position: absolute !important;
	width: 300px !important;
	z-index: 99 !important;
	cursor: default !important;
	background-color: var(--white) !important;
	-webkit-filter: drop-shadow(0 0 0.1rem rgba(0, 0, 0, 0.4)) !important;
	filter: drop-shadow(0 0 0.1rem rgba(0, 0, 0, 0.4)) !important;
	color: var(--black) !important;
	border-radius: 8px !important;
}
.tutorialModal .innerCont {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 20px;
}
.tutorialModal .btnCont {
	margin: 20px;
}
.tutorialModal h4 {
	display: block !important;
}
.tutorialModal:before {
	content: '' !important;
	position: absolute !important;
	width: 0 !important;
	height: 0 !important;
	border: 0.75em solid transparent !important;
	border-top: none !important;
	border-bottom-color: var(--white) !important;
}

.tutorialModal.flipTop {
	bottom: 150%;
}
.tutorialModal.flipTop:before {
	border: none !important;
	border-left: 0.75em solid transparent !important;
	border-right: 0.75em solid transparent !important;
	border-top: 0.75em solid var(--white) !important;
	top: 100%;
}

.tutorialModal.flipBottom {
	top: 150%;
}
.tutorialModal.flipBottom:before {
	bottom: 100%;
}

.tutorialModal.flipRight {
	left: -12.5px;
}
.tutorialModal.flipRight:before {
	left: 1.5em !important;
}

.tutorialModal.flipLeft {
	right: -15px;
}
.tutorialModal.flipLeft:before {
	content: '' !important;
	position: absolute !important;
	width: 0 !important;
	height: 0 !important;
	bottom: 100% !important;
	right: 1.5em !important;
	border: 0.75em solid transparent !important;
	border-top: none !important;
	border-bottom-color: var(--white) !important;
}

.tutorialModal a {
	text-decoration: underline !important;
	color: var(--black) !important;
	font-weight: 400 !important;
}

.tutorialModal.wide {
	width: 400px !important;
}

.tutorialModal.widest {
	width: 600px !important;
}

.tutorialModal .btnCont,
.tutorialPrompt .btnCont {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-orient: horizontal !important;
	-webkit-box-direction: normal !important;
	-ms-flex-direction: row !important;
	flex-direction: row !important;
	-webkit-box-pack: justify !important;
	-ms-flex-pack: justify !important;
	justify-content: space-between !important;
	-webkit-box-align: center !important;
	-ms-flex-align: center !important;
	align-items: center !important;
}

.tutorialModal .btn1,
.tutorialPrompt .btn1 {
	background-color: var(--turqMain) !important;
	color: var(--white) !important;
	border-radius: 25px !important;
	width: 100% !important;
	margin-bottom: 10px !important;
	height: 30px !important;
	cursor: pointer !important;
}
.tutorialModal .btn2,
.tutorialPrompt .btn2 {
	height: 30px !important;
	border-radius: 25px !important;
	width: 100% !important;
	color: var(--gray) !important;
	font-size: 0.875em !important;
	cursor: pointer !important;
}
.tutorialModal .btn1,
.tutorialModal .btn2 {
	width: 120px !important;
	margin: 0px !important;
}

.tutorialModal .btn1:hover,
.tutorialPrompt .btn1:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover) !important;
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover) !important;
	background-color: var(--turqMain) !important;
}
.tutorialModal .btn2:hover,
.tutorialPrompt .btn2:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover) !important;
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover) !important;
	background-color: var(--white) !important;
}

.tutorialModal h4 {
	font-size: 1.125em !important;
	margin: 0 !important;
	font-variation-settings: var(--bold) !important;
	color: var(--black) !important;
}

.tutorialPrompt h3,
.tutorialModal h3 {
	font-variation-settings: var(--bold) !important;
}
.tutorialPrompt h3,
.tutorialModal h3 {
	width: 100% !important;
	margin-bottom: 15px !important;
	text-align: left !important;
}
.tutorialPrompt p,
.tutorialModal p {
	width: 100% !important;
	margin-bottom: 35px !important;
	text-align: left !important;
	font-weight: 400 !important;
	white-space: pre-wrap !important;
}

.supportIconFixed {
	position: fixed;
	right: 10px;
	bottom: 10px;
	height: 24px;
	width: 24px;
	padding: 8px;
	margin: 0px;
	z-index: 80;
	fill: var(--white);
	background-color: var(--turqDark);
	border-radius: var(--borderRadiusBtn);
	cursor: pointer;
}
.supportIconFixed.spaceBottom {
	bottom: 60px;
}
.supportIconFixed:hover {
	border-radius: var(--borderRadiusBtn);
	-webkit-filter: drop-shadow(0px 0px 3px var(--boxShadowDarkBackgroundHover));
	filter: drop-shadow(0px 0px 3px var(--boxShadowDarkBackgroundHover));
}

/* Listing Filter CSS */
.filterCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: var(--filterBg);
	border-radius: var(--borderRadiusReg);
	padding: 10px 20px;
	margin: 40px auto 0px auto;
	max-width: 680px;
}

.filterCont h4 {
	color: var(--gray);
	font-size: 0.94em;
	font-variation-settings: var(--bold);
}

.filterCont .filterHeader {
	color: var(--white);
	margin-right: 10px;
}

.filterType {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0px 5px;
	width: 200px;
	padding: 10px 0px 10px 35px;
	background-color: var(--white);
	border-radius: 8px;
	-webkit-box-shadow: 0px 0px 6px var(--boxShadowDarkest);
	box-shadow: 0px 0px 6px var(--boxShadowDarkest);
}

.filterType .caretRightIconGray {
	margin: auto 8px auto auto;
	min-height: 20px;
	min-width: 10px;
}

/* Notification CSS */
.notificationsCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	max-width: 100%;
	width: 100%;
}
.notificationsCont .innerCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	z-index: 2;
	max-width: 100%;
}
.notificationsCont .notificationsStep {
	box-sizing: border-box;
	width: 100%;
}
.notificationsList {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	box-sizing: border-box;
	align-items: center;
	padding: 0px 10px;
	width: 100%;
}
.notificationsList .notificationsListHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	-ms-flex-item-align: start;
	align-self: flex-start;
	align-items: center;
	margin-bottom: 24px;
	width: 100%;
}

.notificationsList .notificationsListHeader .rowIcons {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.notificationsList .notificationsListHeader .headerIcon {
	fill: var(--gray-500);
	border-radius: 50%;
	cursor: pointer;
	padding: 10px;
	height: 20px;
	width: 20px;
}
.notificationsList .notificationsListHeader .headerIcon:hover {
	background-color: var(--gray-200);
}

.notificationCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	border-radius: 8px;
	cursor: pointer;
	min-height: 70px;
	max-width: 380px;
	width: 100%;
	margin: 8px 0px;
	background-color: var(--white);
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	box-shadow: 0px 0px 4px var(--boxShadowDarkest);
}
.notificationCont.fadeOutSquish {
	--height: 70px;
	--margin: 8px 0px;
	height: 0px;
	opacity: 0;
	margin: 0px;
	-webkit-animation-name: fadeOutSquish;
	animation-name: fadeOutSquish;
	-webkit-animation-duration: 0.5s;
	animation-duration: 0.5s;
}
.notificationCont:hover {
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDarkBackgroundHover);
	box-shadow: 0px 0px 4px var(--boxShadowDarkBackgroundHover);
}
.notificationCont .notificationDate {
	font-size: 0.75em;
	margin-left: auto;
	color: var(--smoke-gray-400);
	font-variation-settings: 'wght' 300;
}
.notificationCont .notificationSubIcon {
	-ms-grid-column-align: center;
	justify-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	margin-left: auto;

	border-radius: var(--borderRadiusBtn);
	margin-right: -8px;
	padding: 5px;
}
.notificationCont .notificationSubIcon:hover {
	background-color: var(--lightGray);
}
.notificationCont .notificationHeader {
	font-size: 0.75em;
}
.notificationCont .bodyText {
	font-size: 0.875em;
	padding-right: 10px;
}
.notificationCont .bodyTextSmall {
	font-size: 0.75em;
	color: var(--gray-500);
}
.notificationCont .mainRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	border-radius: 8px;
	width: 100%;
}
.notificationCont .iconCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
	min-width: 60px;
	border-radius: 8px 0px 0px 8px;
}

.notificationCont .bodyCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	padding: 6px 10px;
}
.notificationCont .bodyCol .colRowTop {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}

.notificationCont .bodyCol .colRowBottom {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	height: 100%;
}

.notificationDropdown:focus {
	color: blue;
}
.notificationDropdown {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDark);
	box-shadow: 0px 0px 4px var(--boxShadowDark);
	border-radius: var(--borderRadiusReg) 0px var(--borderRadiusReg) var(--borderRadiusReg);
	padding: 10px;
	background: var(--white);
	top: 80%;
	right: 20px;
	min-width: 120px;
	z-index: 1;
}
.notificationDropdown p {
	cursor: pointer;
}
.notificationDropdown p:hover {
	color: var(--turqMain);
}

.notificationSettings {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: flex-start;
	-ms-flex-align: flex-start;
	align-items: flex-start;
	box-sizing: border-box;
	padding-bottom: 80px;
	padding-left: 8px;
	width: 100%;
	gap: 16px;
}
.notificationSettings .subHeader {
	font-size: 0.875em;
	padding: 16px 0px;
}
.notificationSettings .categoryHeader {
	font-size: 0.75em;
	color: var(--smoke-gray-400);
}
.notificationSettings .toggle {
	padding: 0px 12px;
}

/* Whats New */
.newsCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 10px;
}
.newsCont .newsItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--white);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	border-radius: 8px;
	margin: 8px 0px;
	width: 100%;
}
.newsCont .newsItem .body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	max-width: 380px;
	padding: 15px;
}
.newsCont .newsItem .body h3 {
	font-size: 1.125em;
}
.newsCont .newsItem .body p {
	font-size: 0.875em;
}
.newsCont .newsItem .body button {
	width: 120px;
	margin: 22px 0px 0px 0px;
}
.newsCont .newsItem .topBar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	max-width: 380px;
	padding: 6px 16px;
	border-radius: 8px 8px 0px 0px;
}
.newsCont .newsItem .topBar.yellow {
	background-color: var(--signalYellow);
}
.newsCont .newsItem .topBar.turq {
	background-color: var(--turqDarker);
}
.newsCont .newsItem .topBar.red {
	background-color: var(--signalRed);
}
.newsCont .newsItem .topBar.purp {
	background-color: var(--newPurp);
}
.newsCont .newsItem .topBar h5,
.newsCont .newsItem .topBar p {
	color: var(--white);
	font-size: 0.75em;
	margin: 0;
}
.newsCont .newsItem .topBar p {
	color: rgba(255, 255, 255, 0.6);
}
.newsCont .newsItem img {
	height: 160px;
	width: 350px;
	max-width: 100%;
	margin: 20px 0px;
}
.newsCont .newsItem button {
	height: 24px;
	font-size: 0.875em;
}
.newsCont .row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	margin: 0px 0px 32px 0px;
}

/* Theme colors */
.iconCol.sale {
	background-color: var(--sale);
}
.notificationHeader.sale {
	color: var(--sale);
}
.iconCol.purchase {
	background-color: var(--purchase);
}
.notificationHeader.purchase {
	color: var(--purchase);
}
.iconCol.message {
	background-color: var(--notification);
}
.notificationHeader.message {
	color: var(--notification);
}
.iconCol.team {
	background-color: var(--team);
}
.notificationHeader.team {
	color: var(--team);
}
.iconCol.custom {
	background-color: var(--gray-500);
}
.notificationHeader.custom {
	color: var(--gray-500);
}

/* Package page */
.packagePageCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	margin: 0px auto;
	padding: 40px;
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDark);
	box-shadow: 0px 0px 4px var(--boxShadowDark);

	border-radius: var(--borderRadiusBtn);
	max-width: 1540px;
	background-color: var(--white);
}

.packagePageCont button {
	width: 200px;
}

/* Canvas */
.cvsCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	background-color: var(--white);
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	overflow: auto;
	height: 900px;
	width: 500px;
}
.cvsCont::-webkit-scrollbar {
	height: 8px;
	width: 0px;
	border: 1px solid #fff;
	overflow-x: scroll;
	overflow-y: hidden;
}
.canvas {
	border: 1px solid var(--gray);
	box-sizing: border-box;
	-ms-touch-action: none;
	border-radius: 8px;
	touch-action: none;
	position: relative;
	height: 300px;
	width: 300px;
	left: 0px;
}
.canvasModal {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: fixed;
	border-radius: var(--borderRadiusCard);
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	background-color: var(--white);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	max-height: 95vh;
	max-width: min(500px, 90vw);
	padding: 12px;
	z-index: 99;
	width: 100%;
	left: 50vw;
	top: 50vh;
}
.canvasModal .cvsHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 18px;
	width: 100%;
	padding-left: 12px;
}
.canvasModal .canvas {
	z-index: 99;
}
.canvasPreview {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border: 1px solid var(--gray-500);
	border-radius: var(--borderRadiusCard);
	background-color: var(--white);
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	overflow: hidden;
	max-width: 100%;
	height: 200px;
	width: 500px;
}
.canvasPreview .canvas {
	border: none;
}
.canvasPreview .overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.4);
	color: var(--white);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	opacity: 0;
	-webkit-transition: opacity 0.5s ease;
	-o-transition: opacity 0.5s ease;
	transition: opacity 0.5s ease;
	z-index: 3;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(3px);
}
.canvasPreview .overlay.mobile {
	opacity: 1;
}
.canvasPreview:hover .overlay {
	opacity: 1;
}
.cvsToolCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border: 1px solid var(--gray-200);
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin-bottom: 30px;
	border-radius: 8px;
	padding: 4px 8px;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	max-width: 80vw;
	width: 500px;
	gap: 6px;
}
.cvsToolCont .separator {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 4px;
}
.cvsToolCont .drawToolIcon {
	fill: var(--gray-500);
	cursor: pointer;
	height: 24px;
	width: 24px;
}
.cvsToolCont .circleIconCont,
.cvsToolCont .circleIconCont svg {
	cursor: pointer;
	height: 24px;
	width: 24px;
}
.cvsToolCont .tool {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 8px;
	max-width: 100px;
	cursor: pointer;
	padding: 8px;
}
.cvsToolCont .tool:hover {
	background-color: var(--gray-100);
}
.cvsToolCont .tool .toolDrop {
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	background-color: var(--white);
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 12px;
	padding: 3px;
	z-index: 20;
	top: 120%;
}
.cvsToolCont .tool .toolDrop .circleIconCont {
	border-radius: 8px;
	padding: 6px;
}
.cvsToolCont .tool .toolDrop .circleIconCont.selected,
.cvsToolCont .tool .toolDrop .circleIconCont:hover {
	background-color: var(--gray-100);
}
.cvsToolCont .tool.flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 6px;
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	background-color: var(--white);
	border-radius: 16px;
	max-width: none;
}
.cvsToolCont .tool.selected {
	background-color: var(--gray-100);
}
.drawTool {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding-bottom: 40px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	max-width: 600px;
	width: 100%;
}

.textToolBox {
	position: absolute;
	z-index: 5;
}

/** BADGE */
.badgeCont {
	display: flex;
	border: 2px solid var(--sentiment-warning-orange, #ef6820);
	background: var(--white, #fff);
	align-items: flex-start;
	box-sizing: border-box;
	border-radius: 12px;
	margin-bottom: 20px;
	width: 1216px;
	padding: 16px;
	gap: 24px;
}
.badgeCont.fill {
	width: 100%;
}
.badgeCont .title {
	color: var(--content-primary, #151c1e);
	font-size: 16px;
	font-weight: 600;
}
.badgeCont .reason {
	color: var(--sentiment-warning-orange, #ef6820);
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 6px;
}
.badgeCont .message {
	color: var(--content-secondary, #485051);
	font-size: 14px;
	font-weight: 300;
	line-height: 20px;
	margin: 0px;
}
.badgeCont .tag {
	color: var(--sentiment-warning-orange, #ef6820);
	font-size: 0.75em;
}
.badgeCont .badge {
	fill: var(--sentiment-warning-orange, #ef6820);
	background: var(--white, #fff);
	border-radius: 6px;
	padding: 2px;
	outline: 2px solid #ef68204c;
	outline-offset: 3px;
	border: 2px solid #ef682077;
	width: 28px;
	margin-top: -6px;
	margin-left: -6px;
	height: 28px;
	flex-shrink: 0;
}
.badgeCont .col {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.badgeCont.warning {
	color: var(--content-secondary, #485051);
}

/* TERMLY */
.termlyCont {
	font-family: 'Nunito Sans', sans-serif !important;
	font-variation-settings: var(--bold);
	margin: 50px auto 0px auto;
	max-width: 1000px;
	width: 80%;
}

.t-consentPrompt span,
.t-consentPrompt button,
#termly-code-snippet-support p,
#termly-code-snippet-support div,
#termly-code-snippet-support h2,
.termly-styles-root-79250e button {
	font-family: 'Nunito Sans', sans-serif !important;
	font-variation-settings: var(--bold);
}
.t-preference-modal {
	border-radius: var(--borderRadiusReg);
}
.termly-styles-root-79250e {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	border-radius: 8px;
}
.t-consentPrompt button,
.termly-styles-root-79250e button {
	font-size: 0.875em;
	border-radius: 25px;
}
.t-consentPrompt button:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
}
.t-consentPrompt .t-preference-button {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	color: var(--gray-500);
	border: none;
}

/* TUTORIALS */
.tutorialsPage {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	max-width: 1540px;
	padding: 60px;
	gap: 20px;
}

.editorJSCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	background-color: var(--white);
	margin: 0px auto 0px auto;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 30px 40px;
	width: 100%;
}

.tutorialList {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	background-color: var(--white);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 30px 20px;
	max-width: 300px;
	width: 30%;
	gap: 6px;
}
.tutorialList .category {
	padding: 6px 12px;
	margin-left: -12px;
	width: 100%;
}
.tutorialList .item {
	cursor: pointer;
	font-variation-settings: 'wght' 400;
	padding: 2px 12px;
	margin-left: -12px;
	width: 100%;
}
.tutorialList .item.selected {
	background-color: var(--primary-25);
	color: var(--turqMain);
}
.tutorialList .item:hover {
	background-color: var(--primary-25);
	color: var(--turqMain);
}

/** THREEJS */
.threeJsCont {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	background-color: var(--white);
	border-radius: 16px;
	position: fixed;
	z-index: 98;
	left: 50%;
	top: 50%;
}
.closeModel {
	position: absolute;
	height: 30px;
	width: 80px;
	margin: 10px 20px;
	right: 0px;
	font-size: 1em;

	border-radius: var(--borderRadiusBtn);
	z-index: 101;
	cursor: pointer;
	border: none;
	color: var(--white);
	background-color: var(--turqMain);
	outline: none;
}
.threeJsCont .stlHeader {
	position: absolute;
	color: var(--black);
	margin: 10px 20px;
	height: 30px;
	left: 0px;
}

.threeJsModel {
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	position: fixed;
	z-index: 101;
	left: 50%;
	top: 50%;
	width: 700px;
}

.rangeSlider {
	position: absolute;
	width: 50%;
	bottom: -50px;
	margin-left: 25%;
	margin-right: 25%;
}

.searchBar {
	position: relative;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border: 1px solid var(--disabled);
	padding: 0px 40px 0px 12px;
	color: var(--black);
	border-radius: 3px;
	margin: 5px 0px;
	font-size: 1em;
	height: 38px;
	width: 100%;
}

/* Empty state */
.emptyStateCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-grid-column-align: center;
	justify-self: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	padding: 120px 0px;
	max-width: 350px;
}
.emptyStateOuterCont {
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	background-color: var(--white);
	min-width: min(600px, 80vw);
	border-radius: var(--borderRadiusCard);
	padding: 24px 24px 0px 24px;
}
.emptyStateOuterCont .thin {
	max-width: 100%;
}
.emptyStateCont.thin {
	padding: 0px;
}
.emptyStateCont .bigIcon {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 150px;
	width: 150px;
}
.emptyStateCont .bigIcon .featuredIcon {
	display: flex;
	width: 48px;
	height: 48px;
	justify-content: center;
	align-items: center;
	align-self: flex-end;
	border-radius: 10px;
	border: 1px solid var(--border-overlay, rgba(30, 57, 61, 0.12));
	background: var(--brand, #ccf7ff);
	margin-bottom: 18px;
}
.emptyStateCont .bigIcon .featuredIcon svg {
	width: 24px;
	height: 24px;
	fill: var(--brand-700);
	flex-shrink: 0;
}
.emptyStateCont .bigIcon svg,
.emptyStateCont .bigIcon .featuredIcon svg {
	position: absolute;
}
.emptyStateCont .emptyStateHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	margin: 24px 0px 8px 0px;
	height: 25px;
	padding: 0px;
}
.emptyStateCont .btnCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	margin: 32px;
	gap: 12px;
}
.emptyStateCont .emptyStateIcon {
	fill: var(--gray-500);
	margin-right: 12px;
	height: 20px;
	width: 20px;
}
.emptyStateCont .emptyStateIcon.colorless {
	fill: none;
}
.emptyStateCont .emptyStateIcon.white {
	fill: var(--white);
}
.emptyStateCont .btn3-primary {
	background-color: var(--primary-600);
}

/** Banner CSS */
.bannerCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background: -o-linear-gradient(45deg, #006c75 0%, #00abbb 100%);
	background: linear-gradient(45deg, #006c75 0%, #00abbb 100%);
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 12px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-item-align: stretch;
	-ms-grid-row-align: stretch;
	align-self: stretch;
	color: var(--white);
	margin-bottom: 48px;
	-webkit-animation: fadeInBanner 0.5s ease-in-out;
	animation: fadeInBanner 0.5s ease-in-out;
	opacity: 1;
	padding: 8px 16px;
	min-height: 52px;
	gap: 24px;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.bannerCont .closeIcon {
	position: absolute;
	fill: var(--white);
	height: 20px;
	width: 20px;
	right: 16px;
}
.bannerCont .thickText {
	font-weight: 600;
}
.bannerCont .thinText {
	font-weight: 300;
}

/** General Listing CSS  */
.listing {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	height: 100%;
	width: 100%;
}
.listing .inner {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-radius: var(--borderRadiusCard);
	background-color: var(--white);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	align-items: center;
	padding: 24px;
	height: 100%;
	gap: 24px;
}
.listing .header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	height: 102px;
	max-width: 1540px;
	padding: 0px 24px 32px 24px;
}
.listing .header h1 {
	font-size: 1.5em;
}
.listing .header svg {
	height: 24px;
	width: 24px;
}
.listOuterCont .lds-dual-ring {
	margin: 213.5px auto;
}
.listOuterCont {
	border: 1px solid var(--gray-300);
	-webkit-box-shadow: var(--shadow-sm);
	box-shadow: var(--shadow-sm);
	padding-bottom: 6px;
	border-radius: 20px;
	overflow-x: hidden;
	height: 100%;
	width: 100%;
}
.listOuterCont .paginationContainer {
	margin: 12px 0px 0px 0px;
	padding: 0px;
}

.listOuterCont .list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--white);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	overflow-x: auto;
	width: 100%;
}
.listOuterCont .list .item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	width: 100%;
}
.listOuterCont .list .item:last-child .data {
	border-bottom: none;
}
.listOuterCont .list .item .data {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	color: var(--gray-900);
	border-bottom: 1px solid var(--gray-300);
	-webkit-box-align: center;
	-ms-flex-align: center;
	word-break: break-word;
	align-items: center;
	font-size: 0.875em;
	padding: 16px 24px;
	font-weight: 700;
	margin: 0px;
	width: 100%;
}
.listOuterCont .list .item .data:nth-child(0),
.listOuterCont .list .item .data:nth-child(1),
.listOuterCont .list .item .data:nth-child(5) {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}
.listOuterCont .list .item .data.wide,
.listOuterCont .list .listHeader .wide {
	min-width: 40%;
}
.listOuterCont .list .item .data .progressIcon {
	height: 32px;
	width: 32px;
}
.listOuterCont .list .item .data .progressIcon.warning {
	fill: var(--warning-500);
}

.listOuterCont .list .listHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-radius: 20px 20px 0px 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	height: 40px;
}
.listOuterCont .list .listHeader h4 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	word-wrap: break-word;
	-webkit-box-align: center;
	-ms-flex-align: center;
	border-bottom: 1px solid var(--gray-300);
	background-color: var(--gray-50);
	align-items: center;
	padding: 0px 24px;
	font-size: 0.75em;
	width: 100%;
}

/* Filter Bar */
.filterBar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	border-radius: var(--borderRadiusReg);
	background-color: var(--gray-50);
	box-sizing: border-box;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 20px;
	width: 100%;
	gap: 12px;
}
.filterBar .field {
	width: 100%;
	max-width: 20%;
	min-width: 200px;
}
.filterBar button {
	max-width: 100%;
	margin: 5px 0px;
}
.filterBar .label {
	font-variation-settings: 'wght' 550;
	font-size: 0.875em;
}

/* Actions Bar */
.actionsBar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-bottom: 1px solid var(--gray-300);
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 20px;
}
.actionsBar .textFieldInput {
	width: 200px;
}

/* Filter Tabs */
.tabRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 100%;
	gap: 12px;
}
.tabGroup {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}
.tabGroup .tab {
	font-variation-settings: 'wght' 650;
	border: 1px solid var(--gray-300);
	border-left: none;
	font-size: 0.875em;
	padding: 10px 16px;
	color: var(--gray);
	cursor: pointer;
}
.tabGroup .tab.selected {
	background-color: var(--gray-50);
	color: var(--black);
}
.tabGroup .tab:first-child {
	border-radius: 20px 0px 0px 20px;
	border-left: 1px solid var(--gray-300);
}
.tabGroup .tab:last-child {
	border-radius: 0px 20px 20px 0px;
}

/* Front page */
.frontPage {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--white);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	border-radius: 24px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	max-width: 1540px;
	overflow: hidden;
	width: 100%;
}
.frontPage .section {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 96px 32px;
	max-width: 1280px;
	width: 100%;
	gap: 64px;
}
.frontPage .screengrab {
	/* border: 4px solid var(--gray-900); */
	border-radius: 10px;
	min-width: 678px;
	margin: 0px 86px;
	height: 512px;
}
.frontPage .section .col {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
	max-width: 768px;
}
.frontPage .section .row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	text-align: center;
	max-width: 100%;
	width: 1280px;
}
.frontPage .section .row.right {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}
.frontPage .section .header {
	letter-spacing: -0.72px;
	font-size: 2.25em;
}
.frontPage .section .paragraph {
	color: var(--gray-700);
	line-height: 30px;
	font-size: 1.25em;
}
.frontPage .section .paragraph.italic {
	color: var(--gray-700);
	line-height: 24px;
	font-size: 1em;
}
.frontPage .featuresIcons {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr 32px 1fr 32px 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	-ms-grid-rows: 1fr 32px 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 32px;
}
.frontPage .featuresIcons > *:nth-child(1) {
	-ms-grid-row: 1;
	-ms-grid-column: 1;
}
.frontPage .featuresIcons > *:nth-child(2) {
	-ms-grid-row: 1;
	-ms-grid-column: 3;
}
.frontPage .featuresIcons > *:nth-child(3) {
	-ms-grid-row: 1;
	-ms-grid-column: 5;
}
.frontPage .featuresIcons > *:nth-child(4) {
	-ms-grid-row: 3;
	-ms-grid-column: 1;
}
.frontPage .featuresIcons > *:nth-child(5) {
	-ms-grid-row: 3;
	-ms-grid-column: 3;
}
.frontPage .featuresIcons > *:nth-child(6) {
	-ms-grid-row: 3;
	-ms-grid-column: 5;
}
.frontPage .featuresIcons .feature {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
	max-width: 384px;
	gap: 20px;
}
.frontPage .featuresList {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	text-align: left;
	min-width: 45%;
	gap: 32px;
}
.frontPage .featuresList .paragraph {
	color: var(--gray-700);
	font-size: 1.125em;
	line-height: 28px;
	margin: 0px;
}
.frontPage .featuresList .listItems {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	text-align: left;
	gap: 20px;
}
.frontPage .featuresList .feature {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	margin-left: 16px;
	gap: 12px;
}
.frontPage .featuresList .feature .paragraph {
	margin-top: -1px;
}
.frontPage .featuresList .header {
	margin: 24px 0px 16px 0px;
	letter-spacing: -0.72px;
	font-size: 1.875em;
	line-height: 38px;
}
.frontPage .icon {
	min-height: 24px;
	min-width: 24px;
	height: 24px;
	width: 24px;
}
.frontPage .videoModal {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: fixed;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	border-radius: var(--borderRadiusReg);
	max-width: 100%;
	height: 100%;
	width: 100%;
	z-index: 99;
	top: 0px;
}
.frontPage .videoModal .videoCont {
	max-width: 100%;
}
.frontPage .videoModal .closeIcon {
	position: absolute;
	fill: var(--gray-200);
	top: -50px;
	right: 0px;
}
.frontPage .videoModal .loadingCont {
	max-width: 100%;
	width: 1050px;
	height: 600px;
}
.frontPage .videoModal .lds-dual-ring {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
	width: 100%;
	margin: 0px;
}

/** Progress Bar CSS  */
.progressBar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: space-between;
	-ms-flex-pack: space-between;
	justify-content: space-between;
	max-height: 8px;
	max-width: 100%;
	width: 100%;
	gap: 6px;
}
.progressBar .rc-progress-line {
	max-height: 8px;
	width: 100%;
}

.signposts {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 6px;
}
.overviewSection.signposts {
	display: flex;
	justify-content: center;
	flex-direction: row;
	flex-wrap: wrap;
	box-sizing: border-box;
	padding: 6px 24px 24px 24px;
	width: 100%;
	max-width: 1540px;
	min-width: 250px;
	border-radius: 25px;
	background-color: var(--overviewBg);
	margin-bottom: 40px;
	gap: 6px;
}
.signpostsList {
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	justify-content: stretch;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	gap: 24px;
}
.signpostsList .signpostItem {
	flex: 1;
	display: flex;
	cursor: pointer;
	border: 1px solid var(--primary-500);
	justify-content: space-between;
	background-color: var(--white);
	box-sizing: border-box;
	border-radius: 16px;
	align-items: center;
	padding: 21px 25px;
	max-height: 90px;
	margin: 1px;
	gap: 12px;
}
.signpostsList .signpostItem button {
	flex: none;
}
.signpostsList .signpostItem:hover {
	background-color: var(--brand-25);
	border: 2px solid var(--interactive-primary-hover);
	margin: 0px;
}
.signpostsList .signpostItem:focus {
	border: 1px solid var(--interactive-primary);
	box-shadow: var(--focus-ring-primary-gap);
	background-color: var(--white);
	outline: none;
}
.signpostsList .signpostItem:active {
	background-color: var(--brand-50);
	border: 2px solid var(--interactive-primary-hover);
	box-shadow: none;
	margin: 0px;
}
.signpostsList .signpostItem h4 {
	color: var(--interactive-primary);
	white-space: nowrap;
}
.signpostsList .signpostItem p {
	color: var(--primary-600);
	white-space: nowrap;
	font-weight: 300;
	margin: 0px;
}
.signpostsList .signpostItem .centerTextCol {
	width: 100%;
}
.signpostsList .signpostItem .arrowIcon {
	fill: var(--interactive-primary);
	height: 24px;
	width: 24px;
}

.signpostsList .signpostItem.team {
	border-color: #ffbb33;
}
.signpostsList .signpostItem.team:hover {
	border: 2px solid var(--sentiment-warning);
	background-color: var(--background-light);
}
.signpostsList .signpostItem.team:focus {
	background-color: var(--background-elevated);
	border: 1px solid var(--sentiment-warning);
	box-shadow: var(--focus-ring-primary-gap);
}
.signpostsList .signpostItem.team:active {
	border: 2px solid var(--sentiment-warning);
	background-color: var(--background-solid);
	box-shadow: none;
}

.signpostsList .signpostItem.sale {
	border-color: var(--sell-new-600);
}
.signpostsList .signpostItem.sale:hover {
	border: 2px solid var(--sell-new-800);
	background-color: var(--sell-new-25);
}
.signpostsList .signpostItem.sale:focus {
	box-shadow: var(--focus-ring-sell-gap);
	border: 1px solid var(--sell-new-600);
	background-color: var(--white);
}
.signpostsList .signpostItem.sale:active {
	border: 2px solid var(--sell-new-800);
	background-color: var(--sell-new-50);
	box-shadow: none;
}
.signpostsList .signpostItem.sale button {
	border-color: var(--sell-new-600) !important;
}
.signpostsList .signpostItem.sale svg {
	fill: var(--sell-new-600);
}
.signpostsList .signpostItem.sale h4,
.signpostsList .signpostItem.sale p {
	color: var(--sell-new-800);
}

.signpostCloseIcon {
	cursor: pointer;
	fill: var(--smoke-gray-600);
	margin-left: auto;
	height: 20px;
	padding: 6px;
	width: 20px;
}

/** Package Choice List */
.packageChoiceList {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	align-self: center;
	gap: 24px;
	flex-shrink: 0;
	max-height: 68vh;
	padding: 0px 12px;
	overflow-y: auto;
	overflow-x: hidden;
	min-width: 320px;
	max-width: 598px;
	width: 100%;
}
.packageChoiceItem {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	box-sizing: border-box;
	align-self: stretch;
	width: 100%;
}
.packageChoiceItem.choice {
	justify-content: center;
}
.packageChoiceItem.choice:hover {
	cursor: pointer;
	box-shadow: var(--shadow-md);
}
.packageChoiceItem .dataIcon {
	fill: var(--content-secondary, #485051);
	height: 20px;
	width: 20px;
}
.packageChoiceItem p {
	display: flex;
	align-items: center;
	color: var(--content-primary, #151c1e);
	margin: 0px;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	gap: 8px;
}
.packageChoiceItem h2,
.packageChoiceItem h4 {
	margin: 0px !important;
}
.packageChoiceItem .cardTitle {
	display: flex;
	height: 64px;
	padding-top: 12px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 16px;
}
.packageChoiceItem .cardTitle h4 {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-variation-settings: 'wght' 700;
	gap: 8px;
	flex-shrink: 0;
	margin: 0px;
}

.memberCont {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	align-self: stretch;
	gap: 10px;
}
.memberCont.listing {
	border-top: 1px solid var(--border-neutral, rgba(30, 57, 61, 0.12));
	padding-top: 16px;
}
.memberCont.listing img {
	box-sizing: border-box;
	border-radius: 50%;
	min-width: 40px;
	max-width: 40px;
	height: 40px;
}
.memberCont .memberRow {
	display: flex;
	align-items: center;
	gap: 10px;
	align-self: stretch;
}
.memberCont .memberRow .userName {
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
}
.memberCont .memberRow .userEmail {
	font-size: 12px;
	font-style: normal;
	font-weight: 300;
	line-height: 18px;
}
.memberCont .memberCol {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.memberCont img {
	max-height: 48px;
	max-width: 48px;
	height: 48px;
	width: 48px;
}

.timestampCont {
	display: flex;
	background: var(--background-transparent, rgba(82, 116, 122, 0.12));
	padding: 12px 12px 12px 16px;
	align-items: flex-end;
	height: 40px;
	gap: 24px;
	align-self: stretch;
	border-radius: 12px;
}
.timestampCont h2 {
	margin: 0px;
}
.timestampCont .stopwatchIcon {
	fill: var(--content-tertiary);
	width: 40px;
	height: 40px;
}
.packageChoiceItem .bottomCont {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
}
.packageChoiceItem .bottomCont .packageDates {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.packageChoiceItem .courierLogo {
	display: flex;
	padding-top: 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
}
.packageChoiceItem .leftCol {
	display: flex;
	min-width: 200px;
	max-width: 300px;
	padding: 24px;
	flex-direction: column;
	align-items: flex-start;
	width: 300px;
	gap: 16px;
	border-radius: 20px 0px 0px 20px;
	background: var(--background-elevated, #fff);
}
.packageChoiceItem .rightCol {
	display: flex;
	padding: 24px;
	min-width: 150px;
	max-width: 242px;
	flex-direction: column;
	align-items: flex-start;
	width: 242px;
	gap: 16px;
	align-self: stretch;
	border-radius: 0px 20px 20px 0px;
	background: var(--background-solid, #ebeeef);
}

/** Package Details */
.packageDetails {
	display: flex;
	padding: 24px;
	flex-direction: column;
	align-items: flex-start;
	box-sizing: border-box;
	width: 100%;
	border-radius: 20px;
	border: 1px solid var(--background-transparent, rgba(82, 116, 122, 0.12));
	background: var(--white, #fff);
	box-shadow: 0px 2px 6px 0px rgba(16, 24, 40, 0.12), 0px 1px 4px 0px rgba(16, 24, 40, 0.06);
}
.packageDetails .row {
	display: flex;
	width: 1342px;
	padding-bottom: 24px;
	justify-content: flex-start;
	align-items: flex-start;
	box-sizing: border-box;
	max-width: 100%;
	flex-wrap: wrap;
	gap: 64px;
}
.packageDetails .row:nth-child(2) {
	display: flex;
	padding: 24px 0px;
	align-items: flex-start;
	align-self: stretch;
}
.packageDetails .row.borderBottom {
	border-bottom: 1px solid var(--border-neutral, rgba(30, 57, 61, 0.12));
}
.packageDetails .memberRow .userName {
	font-weight: 700;
}
.packageDetails .dataIcon {
	fill: var(--content-secondary, #485051);
	height: 20px;
	width: 20px;
}
.packageDetails p {
	display: flex;
	align-items: center;
	color: var(--content-primary, #151c1e);
	margin: 0px;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	gap: 8px;
}
.packageDetails .col {
	display: flex;
	flex-direction: column;
	max-height: 76px;
	flex-wrap: wrap;
	min-width: 15%;
	gap: 16px;
}

/* Order Details Tabs */
.orderDetailsTabs {
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	align-items: center;
	margin: 0px 12px;
	background-color: var(--purchaseBgLight);
	border: 1px solid var(--gray-100);
	border-radius: 8px;
	margin-bottom: 12px;
	padding: 3px;
}
.orderDetailsTabs .tab {
	display: flex;
	cursor: pointer;
	justify-content: center;
	color: var(--gray-600);
	align-items: center;
	border-radius: 4px;
	font-size: 0.875em;
	font-weight: 700;
	padding: 3px;
	width: 50%;
}
.orderDetailsTabs .tab.selected {
	background-color: var(--primary-600);
	color: var(--white);
}

/** Messaging */
.chatBoxCont {
	display: flex;
	background-color: var(--white);
	flex-direction: column;
	box-sizing: border-box;
	border-radius: 12px;
	max-width: 70%;
	width: 100%;
}
.chatBoxCont .messageForm {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	box-sizing: border-box;
	border-radius: 8px;
	background: var(--Background-Elevated, #fff);
	padding: 20px 16px;
	height: inherit;
	margin: auto 0px 0px 0px;
	z-index: 1;
	gap: 12px;
}
.chatBoxCont .messageForm button {
	background: var(--background-subtle-transparent, rgba(109, 166, 178, 0.1));
	fill: var(--Background-Brand, #005262);
}
.chatBoxCont .messageForm:focus-within {
	box-shadow: 0px -4px 8px 0px rgba(1, 34, 41, 0.18);
}
.chatBoxCont .messageForm .chatInput {
	display: flex;
	background: var(--background-subtle-transparent, rgba(109, 166, 178, 0.1));
	border-radius: 12px;
	box-sizing: border-box;
	border-bottom: 2px solid var(--interactive-secondary, #608890);
	height: 48px;
	padding: 10px 12px;
	align-items: center;
	gap: 8px;
	align-self: stretch;
}
.chatBoxCont .chatListCont {
	height: 100%;
	display: flex;
	padding: 24px;
	margin-right: 12px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;
	border-radius: 12px;
	overflow: auto;
}
.chatBoxCont .chatListCont .messageItem {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	gap: 6px;
}
.chatBoxCont .chatListCont .timestampAndNameCont .chatTimestamp {
	color: var(--content-secondary-700, #575d5e);
	font-size: 0.875em;
	font-style: normal;
	font-weight: 300;
	margin: 0px;
}
.chatBoxCont .chatListCont .timestampAndNameCont .messageUserName {
	color: var(--content-secondary-700, #575d5e);
	font-weight: 600;
	margin: 0px;
}

.chatBoxCont .chatListCont .messageItem .timestampAndNameCont {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 6px;
	width: 100%;
}
.chatBoxCont .chatListCont .messageItemUser .timestampAndNameCont {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 6px;
	width: 100%;
}
.chatBoxCont .chatListCont .separator {
	display: flex;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	color: var(--content-tertiary-400, #9ba8ab);
}
.chatBoxCont .chatListCont .separator::before,
.chatBoxCont .chatListCont .separator::after {
	content: '';
	flex: 1;
	border-bottom: 1px solid var(--border-overlay, rgba(47, 70, 75, 0.15)); /* Divider line style */
	margin: 0 10px;
}
.chatBoxCont .chatListCont .messageItem .messageBody {
	display: flex;
	border-radius: 0px 8px 8px 8px;
	background: var(--background-gray-subtle-50, #f2f7f7);
	color: var(--gray-900, #1d2324);
	max-width: 600px;
	padding: 10px 14px;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	margin: 0px;
}
.chatBoxCont .chatListCont .messageItemUser {
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	align-self: stretch;
	flex-direction: column;
	align-items: flex-end;
	gap: 6px;
}
.chatBoxCont .chatListCont .messageItemUser .messageBody {
	display: flex;
	border-radius: 8px 0px 8px 8px;
	background: var(--background-brand, #005262);
	color: var(--base-white, #fff);
	max-width: 600px;
	padding: 10px 14px;
	justify-content: flex-end;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	align-items: center;
	margin: 0px;
	gap: 8px;
}

.chatSelectColumnCont {
	display: flex;
	padding: 20px 12px 24px 0px;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	box-sizing: border-box;
	max-width: 30%;
	width: 320px;
	gap: 24px;
}
.chatSelectColumnCont .chatItem {
	display: flex;
	cursor: pointer;
	background: var(--background-gray-subtle-50, #f2f7f7);
	color: var(--content-secondary-700, #575d5e);
	align-items: center;
	align-self: stretch;
	border-radius: 24px;
	padding-right: 8px;
	height: 40px;
	gap: 8px;
}
.chatSelectColumnCont .chatItem .avatarImg,
.chatSelectColumnCont .chatItem .avatarImgCont {
	height: 40px;
	width: 40px;
}
.chatSelectColumnCont .chatItem .rightGroup {
	display: flex;
	align-items: center;
	margin-left: auto;
	gap: 12px;
}
.chatSelectColumnCont .chatItem .unsentTextIcon {
	fill: gray;
	height: 20px;
	width: 20px;
}
.chatSelectColumnCont .listCont {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	padding-right: 12px;
	overflow: auto;
	height: 100%;
	gap: 8px;
}
.chatSelectColumnCont .chatItem h4 {
	font-variation-settings: 'wght' 550;
	font-size: 1em;
}
.chatSelectColumnCont .chatItem h5 {
	font-variation-settings: 'wght' 450;
	font-size: 0.75em;
}
.chatSelectColumnCont .chatItem.selected {
	background: var(--background-elevated, #fff);
	color: var(--content-primary-900, #142529);
}
.chatSelectColumnCont .chatItem.unread {
	background: var(--background-gray-solid-100, #e6f0f2);
	color: var(--content-primary-900, #142529);
}
.chatSelectColumnCont .chatItem.unread h4,
.chatSelectColumnCont .chatItem.selected h4,
.chatSelectColumnCont .chatItem.unread h5,
.chatSelectColumnCont .chatItem.selected h5 {
	font-variation-settings: 'wght' 650;
}
.chatSelectColumnCont .chatItem .userPlaceholderIcon {
	background-color: var(--docketGreen);
	border-radius: 50%;
	fill: var(--white);
	padding: 10px;
	height: 20px;
	width: 20px;
	flex-shrink: 0;
}

.chatSelectColumnCont .chatSelectTabs {
	display: flex;
	background: var(--background-subtle-transparent, rgba(109, 166, 178, 0.1));
	border: 1px solid var(--border-overlay, rgba(47, 70, 75, 0.15));
	fill: var(--interactive-secondary, #608890);
	justify-content: center;
	align-items: center;
	align-self: stretch;
	border-radius: 100px;
	height: 32px;
}
.chatSelectColumnCont .chatSelectTabs .tabItem {
	display: flex;
	cursor: pointer;
	background: var(--background-transparent-01, rgba(255, 255, 255, 0));
	color: var(--interactive-secondary, #608890);
	justify-content: center;
	border-radius: 100px;
	align-items: center;
	padding: 7px 16px;
	box-sizing: border-box;
	height: 32px;
	flex: 1 0 0;
}
.chatSelectColumnCont .chatSelectTabs .tabItem h4 {
	color: var(--interactive-secondary, #608890);
	font-variation-settings: 'wght' 650;
	font-size: 1em;
}
.chatSelectColumnCont .chatSelectTabs .tabItem svg {
	height: 20px;
	width: 20px;
}
.chatSelectColumnCont .chatSelectTabs .tabItem.selected {
	border: 1px solid var(--border-overlay, rgba(47, 70, 75, 0.15));
	background: var(--interactive-secondary, #608890);
	fill: var(--base-white, #fff);
}
.chatSelectColumnCont .chatSelectTabs .tabItem.selected h4 {
	color: var(--base-white, #fff);
}
.chatSelectColumnCont .displayCount {
	display: flex;
	border: 1px solid var(--border-overlay, rgba(47, 70, 75, 0.15));
	background: var(--sentiment-warning-orange-500, #ef6820);
	color: var(--base-white, #fff);
	justify-content: center;
	border-radius: 100px;
	align-items: center;
	margin-left: auto;
	height: 20px;
	width: 20px;
}
.chatSelectColumnCont .displayCount h4 {
	font-size: 0.75em;
}

.chatWritingCont {
	display: flex;
	background: var(--background-transparent, rgba(120, 172, 182, 0.18));
	border-radius: 0px 8px 8px 8px;
	justify-content: center;
	box-sizing: border-box;
	align-items: center;
	padding: 10px;
	height: 28px;
	width: 40px;
	gap: 4px;
}
.chatWritingCont svg {
	height: 30px;
}

.chatWrapperHeader {
	display: flex;
	padding: 0px 0px 24px 0px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
}
.chatWrapperHeader .leftRow {
	display: flex;
	align-items: center;
	gap: 16px;
}
.chatWrapperHeader .leftRow h1 {
	color: var(--content-primary-900, #142529);
	font-size: 24px;
	line-height: 32px;
	letter-spacing: -0.48px;
}
.chatWrapperHeader .leftRow .messagingPageIcon {
	display: flex;
	width: 48px;
	height: 48px;
	padding: 12px;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	border: 1px solid var(--Border-Overlay, rgba(47, 70, 75, 0.15));
	background: var(--Base-White, #fff);
	box-sizing: border-box;
}
.chatMainCont {
	display: flex;
	flex-direction: row;
	height: 70vh;
}

.chatBoxHeader {
	display: flex;
	padding: 16px 24px 16px 16px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	border-radius: 0px 12px 0px 0px;
	border-bottom: 1px solid var(--border-dark-transparent, rgba(71, 107, 114, 0.3));
}
.chatBoxHeader .leftGroup {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 2px;
}
.chatBoxHeader .rightGroup {
	display: flex;
	justify-content: center;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	gap: 8px;
}
.chatBoxHeader .leftGroup .chatTitle {
	font-size: 1.25em;
}

/** Modals */
.modalPortalBackdrop {
	position: absolute;
	z-index: 94;
	height: 100%;
	width: 100%;
	left: 0px;
	top: 0px;
	background-color: rgba(0, 0, 0, 0.6);
	-webkit-backdrop-filter: blur(8px);
	backdrop-filter: blur(8px);
	border-radius: 16px;
}
.modalPortal .innerContentModal {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	border-radius: 16px;
	background: var(--background-gray-subtle-50, #f2f7f7);
}
.modalPortal .innerContentModal .headerSection {
	display: flex;
	padding: 40px;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
}
.modalPortal .innerContentModal .headerSection .boldSpan {
	color: var(--content-secondary-700, #575d5e);
	font-weight: 700;
}
.modalPortal .innerContentModal .headerSection .subheading {
	color: var(--primary-700, #006c75);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	margin: 0px 0px 12px 0px;
}
.modalPortal .innerContentModal .headerSection h2 {
	font-size: 2.25em;
	line-height: 44px;
	letter-spacing: -0.72px;
	margin-bottom: 24px;
	color: var(--content-primary-900, #142529);
}
.modalPortal .innerContentModal .headerSection p {
	color: var(--content-secondary-700, #575d5e);
	font-size: 1.25em;
	font-weight: 400;
	line-height: 24px;
	margin: 0px 0px 40px 0px;
	max-width: 100%;
	width: 768px;
}
.modalPortal .innerContentModal .headerSection .btnCont {
	gap: 24px;
}

/** Table */
.table-container {
	display: flex;
	border: 1px solid var(--border-overlay, rgba(47, 70, 75, 0.15));
	background: var(--background-elevated, #fff);
	align-items: flex-start;
	margin-top: 72px;
	border-radius: 16px;
	max-width: 100%;
}

.custom-table {
	width: 100%;
	border-collapse: collapse;
	table-layout: fixed;
	text-align: left;
	background-color: var(--white);
	border-radius: 16px;
	border-style: hidden;
}

.custom-table .checkIcon {
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	fill: var(--sentiment-positibe, #00854d);
}
.custom-table .minusIcon {
	width: 20px;
	height: 20px;
	flex-shrink: 0;
	fill: var(--content-secondary-700, #575d5e);
}
.custom-table .infoTooltipIcon {
	margin-right: 6px;
	margin-left: 6px;
}

.custom-table tr:last-child td:first-child {
	border-radius: 0px 0px 0px 16px;
}
.custom-table tr:last-child td:last-child {
	border-radius: 0px 0px 16px 0px;
}
.custom-table thead {
	border-bottom: 1px solid var(--gray-200, #eaecf0);
}
.custom-table th,
.custom-table td {
	box-sizing: border-box;
	padding: 0px 24px;
}

.custom-table th {
	font-variation-settings: 'wght' 650;
	text-align: center;
	height: 60px;
}

.custom-table td {
	font-variation-settings: 'wght' 550;
	text-align: center;
	height: 64px;
}
.custom-table .rowData {
	color: var(--content-secondary-700, #575d5e);
}
.custom-table td span {
	display: inline-block;
	width: 20px;
	height: 20px;
	line-height: 20px;
	text-align: center;
}
.custom-table .rowLabel {
	display: flex;
	align-items: center;
	text-align: left;
}
.custom-table .defaultRow,
.custom-table .subheaderRow {
	font-size: 1.125em;
}
.custom-table .clearRow td {
	font-variation-settings: 'wght' 650;
}
.custom-table .subheaderRow td {
	padding-top: 24px;
	height: 76px;
}
.custom-table .subheaderRow:first-child td {
	padding-top: 0px;
	height: 48px;
}
.custom-table .subheaderRow .rowLabel {
	color: var(--content-brand-700, #087f91);
}
.custom-table .defaultRow:nth-child(even) {
	background: var(--background-gray-subtle-50, #f2f7f7);
}

/** Media queries */
@media only screen and (max-width: 1416px) {
	.signpostsList {
		grid-template-columns: auto auto;
	}
}
@media only screen and (max-width: 1312px) {
	.pageContainer {
		margin-left: 70px;
	}
	.navbar {
		margin-left: 105px;
	}
}
@media only screen and (max-width: 1280px) {
	.list .item .data {
		padding: 12px 8px !important;
	}
	.list .listHeader h4 {
		padding: 16px 8px !important;
	}
	.topbar .link {
		margin: 0;
	}
	.topbar {
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}
}

@media only screen and (max-width: 1024px) {
	.app_tile {
		width: 350px;
	}

	.tutorialsPage {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.tutorialList {
		max-width: 100%;
		width: 100%;
	}
	.listOuterCont h4,
	.listOuterCont .data {
		min-width: 80px !important;
	}
	.signpostsList {
		gap: 12px;
	}
	.signpostsList .signpostItem {
		padding: 11px 15px;
	}
}

@media only screen and (max-width: 768px) {
	.listing .inner {
		padding: 12px;
	}
	.pageInnerCont {
		border-radius: 0px;
		padding: 0px;
	}

	.topbar h4 {
		display: none;
	}

	.navItems .navItem {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.navbarOuter .navbar .navItems {
		gap: 10px;
	}

	.logo {
		width: 165px;
		height: 36px;
		background: url('../assets/svg/procuur-logo.svg');
		background-size: 165px 36px;
		margin: 20px 0px;
	}

	.supplierList {
		padding: 10px;
	}

	.supplierListCont .supplier {
		height: 80px;
	}

	.supplierListCont .supplier .bodyRow .iconRow .phone,
	.supplierListCont .supplier .bodyRow .iconRow .chat {
		display: none;
	}
	.supplierListCont .supplier .bodyRow .linkText {
		max-width: 100%;
	}

	.tutorialModal {
		max-width: 50vw;
		max-height: 60vh;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	.tutorialModal .btnCont {
		margin: 20px;
	}
	.tutorialModal .innerCont {
		overflow-y: auto;
		max-height: 40vh;
	}
	.navbarOuter.scroll .navbar .logo {
		background-size: 200px 36px;
		margin: 12px 0px;
		height: 36px;
		width: 200px;
	}
	.frontPage .featuresIcons {
		display: -ms-grid;
		display: grid;
		-ms-grid-columns: 1fr 32px 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: 1fr 32px 1fr 32px 1fr;
		grid-template-rows: 1fr 1fr 1fr;
		gap: 32px;
	}
	.frontPage .featuresIcons > *:nth-child(1) {
		-ms-grid-row: 1;
		-ms-grid-column: 1;
	}
	.frontPage .featuresIcons > *:nth-child(2) {
		-ms-grid-row: 1;
		-ms-grid-column: 3;
	}
	.frontPage .featuresIcons > *:nth-child(3) {
		-ms-grid-row: 3;
		-ms-grid-column: 1;
	}
	.frontPage .featuresIcons > *:nth-child(4) {
		-ms-grid-row: 3;
		-ms-grid-column: 3;
	}
	.frontPage .featuresIcons > *:nth-child(5) {
		-ms-grid-row: 5;
		-ms-grid-column: 1;
	}
	.frontPage .featuresIcons > *:nth-child(6) {
		-ms-grid-row: 5;
		-ms-grid-column: 3;
	}
	.frontPage .section .screengrab {
		display: none;
	}
	.pageInnerCont .wrapperInner {
		border-radius: 0px;
	}
	.bannerCont {
		padding: 24px 16px;
	}
	.bannerCont .closeIcon {
		right: 16px;
		top: 6px;
	}
	.canvasModal .closeIcon {
		position: absolute;
		fill: var(--white);
		right: 0px;
		top: -40px;
	}
	.signpostsList {
		grid-template-columns: auto;
	}
	.chatSelectColumnCont .listCont {
		max-width: 100%;
	}
	.chatSelectColumnCont .chatSelectTabs .tabItem {
		padding: 4px 8px;
	}
	.chatSelectColumnCont .chatItem {
		flex-direction: column;
		padding: 6px 0px;
		height: inherit;
		align-items: center;
	}
	.chatSelectColumnCont .chatItem .teamChoicesIcon {
		margin: 0px;
	}
	.chatBoxHeader {
		flex-direction: column;
		gap: 12px;
	}
}

@media only screen and (max-width: 680px) {
	.logo {
		background-size: 112px 24px;
		height: 24px;
		width: 112px;
		margin: 0px;
	}
	.navbarOuter .navbar {
		padding-left: 54px;
		padding-right: 6px;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		height: 48px;
		margin: 6px;
	}
	.navbarOuter.scroll .navbar .logo {
		background-size: 112px 24px;
		height: 24px;
		width: 112px;
		margin: 0px;
	}
	.navItems .navItem {
		cursor: pointer;
		height: 36px;
		width: 36px;
	}
	.navItems .navIcon {
		height: 36px;
		width: 36px;
	}
	.hamburgerIcon {
		position: absolute;
		cursor: pointer;
		z-index: 11;
		height: 32px;
		width: 32px;
		left: 6px;
		bottom: auto;
		top: auto;
	}
	.hamburgerIcon:hover {
		background-color: var(--white);
		border-radius: 6px;
	}
	.hamburgerIcon .menuIcon {
		border-radius: 6px;
		padding: 6px;
		height: 20px;
		width: 20px;
	}
	.pageContainer {
		margin: 0px auto;
	}
}
@media only screen and (max-width: 648px) {
	.topbar h4 {
		display: none;
	}
	.supplierListCont .supplier .bodyRow p {
		display: none;
	}
	.supplierListCont .filterHeader p:nth-child(2) {
		display: none;
	}

	.navItems .dropdown {
		position: absolute;
		min-width: 230px;
		width: 90vw;
		margin: 0px 20px;
		border-radius: var(--borderRadiusReg);
		left: calc(-50vw + 50%);
		z-index: 1;
	}
	.tutorialsPage {
		padding: 12px;
	}
	.editorJSCont {
		padding: 20px 30px;
	}
	.pageInnerCont .wrapperInner {
		padding: 24px 12px;
	}
	.listing .filterBar {
		padding: 12px 6px;
	}
	.packageChoiceList {
		max-width: 340px;
		width: 100%;
	}
	.packageChoiceList::-webkit-scrollbar {
		display: none;
	}
	.packageChoiceItem {
		flex-direction: column;
	}
	.packageChoiceItem .leftCol {
		border-radius: 20px 20px 0px 0px;
		box-sizing: border-box;
		max-width: none;
		padding: 12px;
		width: 100%;
	}
	.packageChoiceItem .rightCol {
		border-radius: 0px 0px 20px 20px;
		flex-direction: column-reverse;
		box-sizing: border-box;
		max-width: none;
		padding: 16px;
		width: 100%;
	}
	.packageChoiceList .packageChoiceItem .timestampCont {
		width: 50%;
	}
	.packageChoiceList .packageChoiceItem .bottomCont {
		flex-direction: row;
	}
	.signpostsList .signpostItem .btn.icon {
		display: none;
	}
	.overviewSection.signposts {
		padding: 12px;
		border-radius: 0px;
	}
}

@media only screen and (max-width: 465px) {
	.centerContainer {
		padding: 0px;
		width: 100%;
	}
	.tutorialPrompt {
		max-width: 100vw;
		bottom: 60px;
		right: 0px;
	}
	.navItems .navItem {
		margin: 3px;
	}
	.emptyStateCont {
		padding: 24px 6px;
	}
	.emptyStateCont .btnCont {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		margin: 32px;
		gap: 12px;
	}
	.frontPage .featuresIcons {
		display: -ms-grid;
		display: grid;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		-ms-grid-rows: 1fr 32px 1fr 32px 1fr;
		grid-template-rows: 1fr 1fr 1fr;
		gap: 32px;
	}
	.frontPage .featuresIcons > *:nth-child(1) {
		-ms-grid-row: 1;
		-ms-grid-column: 1;
	}
	.frontPage .featuresIcons > *:nth-child(2) {
		-ms-grid-row: 3;
		-ms-grid-column: 1;
	}
	.frontPage .featuresIcons > *:nth-child(3) {
		-ms-grid-row: 5;
		-ms-grid-column: 1;
	}
	.frontPage .section {
		padding: 24px;
	}
	.tabGroup .tab {
		padding: 4px 8px;
	}
	.filterBar .field {
		max-width: 100%;
		min-width: 100%;
	}
	.filterBar .field .selectCustom {
		min-width: 100%;
	}
}
